import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExperimentMeasurementStore } from './ExperimentMeasurement';
import { ExperimentStore } from './Experiment';

export class ExperimentVariety extends Model {
    static backendResourceName = 'experiment_variety'
    @observable id = null;
    @observable name = null;

    relations() {
      return {
        experiments: ExperimentStore,
        measurements: ExperimentMeasurementStore,
      }
    }
  }

  export class ExperimentVarietyStore extends Store {
    Model = ExperimentVariety
    static backendResourceName = 'experiment_variety'
  }
