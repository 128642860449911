import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';

const ScrollModal = styled(Modal)`
    height: calc(90vh - 2em) !important;
    flex-direction: column;
    display: flex !important;
    &.visible.transition {
        display: flex !important;
    }
    > * {
        flex: 0 0 auto;
    }
    > .content.scrolling {
        flex: 1 1 auto;
        overflow-y: hidden !important;
        max-height: unset !important;
    }
    > .content {
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    }
    > .content:last-child {
        border-bottom: unset;
    }
    > .actions {
        border-top: unset !important;
    }
`;

const StyledModalContent = styled(Modal.Content)`
    ${({ noPadding }) => noPadding ? `
        padding: 0 !important;
    ` : ``}
`;

const ContentContainer = styled.div`
    ${({ noPadding }) => noPadding ? `` : `
        padding: 1.5rem;
    `}
    ${({ noScrollbars }) => noScrollbars ? `
        height: 100%;
    ` : `
        min-height: 100%;
    `}
    ${({hasHorizontalbar}) => hasHorizontalbar ? `
      width: fit-content;
    ` : ``
    }
    overflow: hidden;
`;

class ScrollModalContent extends Component {
    static propTypes = {
        fixed: PropTypes.bool,
        noPadding: PropTypes.bool,
        noScrollbars: PropTypes.bool,
        hasHorizontalbar: PropTypes.bool,
        children: PropTypes.node,
    };

    static defaultProps = {
        fixed: false,
        noPadding: false,
        noScrollbars: false,
        hasHorizontalbar: false,
        children: null,
    };

    render() {
        let { fixed, noPadding, children, hasHorizontalbar, noScrollbars, ...props } = this.props;

        if (!fixed) {
            children = (
                <ContentContainer noScrollbars={noScrollbars} noPadding={noPadding} hasHorizontalbar={hasHorizontalbar}>{children}</ContentContainer>
            );
            if (!noScrollbars) {
                children = (
                    <Scrollbars>{children}</Scrollbars>
                );
            }
        }

        return (
            <StyledModalContent
                {...props}
                noPadding={!fixed || noPadding}
                scrolling={!fixed}
            >
                {children}
            </StyledModalContent>
        );
    }
}

ScrollModal.Content = ScrollModalContent;

export default ScrollModal;
