import React, { Component } from 'react'
import { Heading, Subheading } from 're-cy-cle'
import FullScreenOverlay from 'component/FullScreenOverlay'
import { Button } from 'semantic-ui-react'
import { observer } from 'mobx-react'

@observer
export default class NoPermissions extends Component {
    render() {
      const { viewStore, history } = this.props
      console.log('viewStore', viewStore)
      return (
        <FullScreenOverlay
            data-test-no-permission-group-overlay
        >
            <Heading>{t('permissions.heading')}</Heading>
            <Subheading>
                {t('permissions.subHeading1')}
                <br />
                {t('permissions.subHeading2')}
                <br />
            </Subheading>
            <Button
              primary
              data-test-log-out-button
              onClick={() => viewStore.performLogout()}
              size="massive"
              icon="sign-out"
              labelPosition="left"
              content={t('nav.account.logout')}
            />
        </FullScreenOverlay>
      )
    }
  }
  