import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Experiment } from './Experiment';
import { ProductionLine } from './ProductionLine';

export class ExperimentChamber extends Model {
    static backendResourceName = 'experiment_chamber'
    @observable id = null;

    relations() {
        return {
            experiment: Experiment,
            chamber: ProductionLine
        }
    }
  }

  export class ExperimentChamberStore extends Store {
    Model = ExperimentChamber
    static backendResourceName = 'experiment_chamber'
  }
