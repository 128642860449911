import { PLCChoice } from "./PlcChoice"
import { ValueStore } from "./Value"
import { Model, Store } from "./Base"
import { observable } from 'mobx'
import { PLCOptionValueStore } from "./PlcOptionValue"
import { StepRegisterValueStore } from "./StepRegisterValue"

export class PLCOption extends Model {
    static backendResourceName = 'plc_option'
    @observable id = null
    @observable name = ''

    relations() {
        return {
            choice: PLCChoice,
            registers: ValueStore,
            values: PLCOptionValueStore,
            stepOptions: StepRegisterValueStore
        }
    }
}

export class PLCOptionStore extends Store {
    Model = PLCOption
    static backendResourceName = 'plc_option'
}
