import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Integration } from './Integration'

export class NavisionIntegration extends Model {
  static backendResourceName = 'navision_integration'
  static omitFields = ['active']

  @observable id = null
  @observable company = null
  @observable active = false

  /** START Topic flags */
  @observable item = true
  @observable productionOrder = true
  @observable purchaseOrder = true
  @observable salesOrder = true
  @observable jobs = true
  @observable customer = true
  /** END Topic flags */

  relations() {
    return {
      integration: Integration,
    }
  }
}

export class Unit4IntegrationStore extends Store {
  static backendResourceName = 'navision_integration'
  Model = NavisionIntegration 
}
