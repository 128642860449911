import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import {PLC} from './Plc'
import {ValueStore} from './Value'


export class Fins extends Model {
  static backendResourceName = 'fins'
  @observable id = null
  @observable ipAddress = ''

  async update(register) {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}update/`, {"register": register}))
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }

  async push(register, value) {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}push/`, {"register": register, "value": value}))
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }

  async pattern(pattern) {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}pattern/`, {"pattern": pattern}))
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }

  async start() {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}start/`))
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }


  relations() {
    return {
        plc: PLC,
        registers: ValueStore,
      }
  }

}

export class FinsStore extends Store {
    Model = Fins
    static backendResourceName = 'fins'
}
