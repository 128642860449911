import { observable } from 'mobx'
import moment from 'moment'
import { Model, Store, Casts } from 'store/Base'
import { WorkSchedule } from './WorkSchedule'

export class WorkSlot extends Model {
  static backendResourceName = 'work_slot'
  static DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  @observable id = null
  @observable monday = true
  @observable tuesday = true
  @observable wednesday = true
  @observable thursday = true
  @observable friday = true
  @observable saturday = false
  @observable sunday = false
  @observable startTime = moment({ hour: 9, minute: 0 })
  @observable endTime = moment({ hour: 17, minute: 0 })

  relations() {
    return {
      workSchedule: WorkSchedule,
    }
  }

  casts() {
    return {
      startTime: Casts.momentTime,
      endTime: Casts.momentTime,
    }
  }
}

export class WorkSlotStore extends Store {
  Model = WorkSlot
  static backendResourceName = 'work_slot'
}
