import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { Button, Form, Header, Modal } from 'semantic-ui-react'
import { TargetTextInput } from 'spider/semantic-ui/Target'
import { WorkStationStore } from 'store/WorkStation'
import Login from '../spider/semantic-ui/Login'

@observer
export class WorkspaceLoginModal extends Login {
  renderForgotPasswordLink() {
    return null
  }

  @observable code = ''
  @observable errors = []

  onCodeChange = (value) => {
    this.code = value
  }

  handleSubmit = () => {
    const { viewStore, history, afterLogin } = this.props

    const store = new WorkStationStore()

    store
      .start(this.code)
      .then(() => viewStore.fetchBootstrap())
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.code === 'ValidationError') {
          this.errors = err.response.data.errors.map(({ message }) => message)
        }
      })
      .then(() => {
        afterLogin()
      })
  }

  render() {
    return (
      <Modal data-test-workstation-login-modal open centered={false} size="mini">
        <Modal.Header as="h2">{t('workStation.start.title')}</Modal.Header>
        <Modal.Content>
          <p>{t('user.login.reloginMessage')}</p>
          <Form>
            <TargetTextInput
              ref={(ref) => (this.input = ref)}
              label={t('workStation.field.code.label')}
              errors={this.errors}
              value={this.code}
              onChange={this.onCodeChange}
            />
            <Button
              fluid
              primary
              data-test-work-station-start-button
              content={t('workStation.start.startButton')}
              onClick={this.handleSubmit}
            />
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
