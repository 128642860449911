import i18next from 'i18next'

import en from './translation/en'
import nl from './translation/nl'
import de from './translation/de'


// add translations used in spider components
// these may be overriden on a project level
i18next.addResourceBundle('en', 'spider', en)
i18next.addResourceBundle('nl', 'spider', nl)
i18next.addResourceBundle('de', 'spider', de)
