import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactSalesOrderLineStore } from 'store/ExactSalesOrderLine'
import { ExactAccount } from 'store/ExactAccount'
import { ExactAddress } from 'store/ExactAddress'

export const STATUS_OPEN = 10
export const STATUS_PARTIAL = 20
export const STATUS_COMPLETE = 30
export const STATUS_CANCELED = 40
export const STATUSES = [STATUS_OPEN, STATUS_PARTIAL, STATUS_COMPLETE, STATUS_CANCELED]

export class ExactSalesOrder extends Model {
  static backendResourceName = 'exact_sales_order'
  static idColor = 'red'
  static idIcon = 'exact'

  getUrl() {
    let salesUrl = this.api.baseUrl + this.url + 'exact/'
    salesUrl = salesUrl.replace(/([^:])(\/\/+)/g, '$1/')

    return salesUrl
  }

  getLink(props = {}) {
    return super.getLink({
      target: '_blank',
      ...props,
    })
  }

  getTarget() {
    return '_blank'
  }

  @observable id = null
  @observable number = null
  @observable status = STATUS_OPEN
  @observable deliveryStatus = STATUS_OPEN
  @observable deliverAt = null
  @observable orderedAt = null
  @observable modified = null
  @observable invoiceStatus = STATUS_OPEN
  //invoice status

  @computed
  get _labelContent() {
    return this.number
  }

  relations() {
    return {
      lines: ExactSalesOrderLineStore,
      deliverTo: ExactAccount,
      deliveryAddress: ExactAddress,
    }
  }

  casts() {
    return {
      deliverAt: Casts.date,
      orderedAt: Casts.date,
      modified: Casts.datetime,
    }
  }
}

export class SalesOrderStore extends Store {
  static backendResourceName = 'exact_sales_order'
  Model = ExactSalesOrder
}
