import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExperimentMeasurement } from './ExperimentMeasurement'
import { ExperimentTreatment } from './ExperimentTreatment'
import { ExperimentVariable } from './ExperimentVariable'
import { ExperimentVariety } from './ExperimentVariety'
import { User } from './User'

export class ExperimentMeasurementValue extends Model {
    static backendResourceName = 'experiment_measurement_value'
    @observable id = null
    @observable plantNumber = 0
    @observable value = null

    relations() {
      return {
        measurement: ExperimentMeasurement,
        treatment: ExperimentTreatment,
        variable: ExperimentVariable,
        variety: ExperimentVariety,
        addedBy: User,
      }
    }
  }

  export class ExperimentMeasurementValueStore extends Store {
    Model = ExperimentMeasurementValue
    static backendResourceName = 'experiment_measurement_value'
  }
