import i18next from 'i18next'
import en from 'i18n/translation/en'
import nl from 'i18n/translation/nl'
//import de from 'i18n/translation/de'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'

i18next.init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: { tracy: en },
    nl: { tracy: nl },
    // de: { tracy: de }, German support is removed
  },
  defaultNS: 'tracy',
  fallbackNS: 'spider',
  interpolation: {
    escapeValue: false, // not needed for react as it does escape per default to prevent xss!
  },
})

export default i18next
export function t(key, ...params) {
  if (isFeatureFlagEnabled('blue_skies')) {
    const bsKey = `blueSkiesOverride.${key}`
    if (i18next.exists(bsKey)) {
      return i18next.t(bsKey, ...params)
    }
  }
  return i18next.t(key, ...params)
}
