import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType.js'
import { StorageLocation } from './StorageLocation'
import { Warehouse } from './Warehouse.js'

export class ArticleTypeWarehouse extends Model {
  static backendResourceName = 'article_type_warehouse'

  @observable id = null

  // aggregated over all storage location stock positions
  @observable stock = 0
  @observable plannedStockIn = 0
  @observable projectedStock = 0


  relations() {
    return {
      articleType: ArticleType,
      defaultStorageLocation: StorageLocation,
      warehouse: Warehouse,
    }
  }
}

export class ArticleTypeWarehouseStore extends Store {
  Model = ArticleTypeWarehouse
  static backendResourceName = 'article_type_warehouse'
}
