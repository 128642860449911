export default {
  color: {
    red: 'Rood',
    orange: 'Oranje',
    yellow: 'Geel',
    olive: 'Olijfgroen',
    green: 'Groen',
    teal: 'Turquoise',
    blue: 'Blauw',
    violet: 'Violet',
    purple: 'Paars',
    pink: 'Roze',
    brown: 'Bruin',
    grey: 'Grijs',
    black: 'Zwart',
  },
  target: {
    TargetColorSelect: {
      label: 'Kleur',
    }
  }
}
