import { Model, Store, Cast } from 'store/Base'
import { observable } from 'mobx'
import { Casts } from './Base'

export class NavisionPurchaseOrderLine extends Model {
    @observable id = null
    @observable promisedReceiptDate = null
    @observable requestedReceiptDate = null
    
    casts() {
        return {
            promisedReceiptDate: Casts.date,
            requestedReceiptDate: Casts.date,
        }
    }

}