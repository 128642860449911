import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ProductionLine } from './ProductionLine'
import { Script } from './Script'
import { ScriptPackageAllocation } from './ScriptPackageAllocation'

export class ScriptAllocation extends Model {
  static backendResourceName = 'script_allocation'
  static omitFields = ['startDate', 'endDate']

  @observable id = null
  @observable rack = 1
  @observable layer = 1
  @observable position = 1
  @observable tank = 1
  @observable group = 1

  @observable startAt = null
  @observable endAt = null

  @observable startDate = null
  @observable endDate = null

  relations() {
    return {
      plantationLine: ProductionLine,
      script: Script,
      packageAllocation: ScriptPackageAllocation,
    }
  }

  casts() {
    return {
      startAt: Casts.datetime,
      endAt: Casts.datetime,
      startDate: Casts.date,
      endDate: Casts.date,
    }
  }
}

export class ScriptAllocationStore extends Store {
  static backendResourceName = 'script_allocation'
  Model = ScriptAllocation
}
