import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleTypeStore } from './ArticleType'

export class Classification extends Model {
  static backendResourceName = 'classification'
  @observable id = null
  @observable name = ''

  relations() {
    return {
      articleTypes: ArticleTypeStore,
    }
  }
}

export class ClassificationStore extends Store {
  Model = Classification
  static backendResourceName = 'classification'
}
