import {Model, Store} from 'store/Base';
import {observable} from "mobx";
import { PlantationState } from "./PlantationState";
import { PlantationAllocation } from "./PlantationAllocation";

export class PlantationPositionAllocation extends Model {
    static backendResourceName = 'plantation_position_allocation';

    @observable id = null;
    @observable x = null;
    @observable y = null;
    @observable z = null;

    get status() {
      if (this.plantationAllocation.productionRequest.isNew){
        return 'blue';
      } else {
        return 'green';
      }
    }

    relations() {
        return {
          plantationState: PlantationState,
          plantationAllocation: PlantationAllocation,
        };
    }
}

export class PlantationPositionAllocationStore extends Store {
    Model = PlantationPositionAllocation;
    static backendResourceName = 'plantation_position_allocation';
}
