import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { Category } from './Category'
import { STATUS_PRIO, IssueStore } from './Issue'
import { StakeholderStore } from './Stakeholder'

export class Topic extends Model {
  static backendResourceName = 'topic'

  static STATUSES = ['red', 'orange', 'purple', 'green', 'grey']

  @observable id = null
  @observable name = ''
  @observable locations = []

  @computed get status() {
    let maxStatus = 'grey'
    for (const issue of this.issues.models) {
      if (STATUS_PRIO[issue.status] > STATUS_PRIO[maxStatus]) {
        maxStatus = issue.status
      }
    }
    return maxStatus
  }

  getStatusByStakeholder(stakeholderId) {
    if (!stakeholderId) {
      return this.status
    }

    let maxStatus = 'grey'
    for (const issue of this.issues.models) {
      if (!issue.hasStakeholder(stakeholderId)) continue

      if (STATUS_PRIO[issue.status] > STATUS_PRIO[maxStatus]) {
        maxStatus = issue.status
      }
    }
    return maxStatus
  }

  @computed get locationIsBlocked() {
    return this.issues.models.some((issue) => issue.blocking)
  }

  relations() {
    return {
      category: Category,
      issues: IssueStore,
      responsible: StakeholderStore,
    }
  }

  hasStakeholder(stakeholderId) {
    return stakeholderId === null || this.responsible.models.some((stakeholder) => stakeholder.id === stakeholderId)
  }
}

export class TopicStore extends Store {
  Model = Topic
  static backendResourceName = 'topic'
}
