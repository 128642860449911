import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { SalesOrder } from './SalesOrder'
import { ArticleType } from './ArticleType'
import { Project } from './Project'
import { ExactSalesOrderLine } from './ExactSalesOrderLine'
import { NavisionSalesOrderLine } from './NavisionSalesOrderLine'
import { NavisionProjectLine } from './NavisionProjectLine'
import { ResourceAllocationStore } from './ResourceAllocation'
import { OutShipmentLineStore } from './OutShipmentLine'

export const STATUS_OPEN = 'open'
export const STATUS_PARTIAL = 'partial'
export const STATUS_COMPLETE = 'complete'
export const STATUS_CANCELED = 'canceled'
export const STATUSES = [STATUS_OPEN, STATUS_PARTIAL, STATUS_COMPLETE, STATUS_CANCELED]

export class SalesOrderLine extends Model {
  static backendResourceName = 'sales_order_line'
  static omitFields = ['quantityDeliveredErp', 'quantityReturnedErp', 'batchCount']

  @observable id = null
  @observable quantity = 0
  @observable unitPrice = 0
  @observable netPrice = 0
  @observable productionDueDate = null
  @observable shippable = true
  @observable deliveryDate = null
  @observable itemCode = null
  @observable description = ''

  @observable batchCount = 0
  @observable quantityDeliveredErp = 0
  @observable quantityReturnedErp = 0
  @observable deliveryStatus = STATUS_OPEN

  @computed get allocated() {
    return this.resourceAllocations.models.reduce((allocated, { quantity }) => allocated + quantity, 0)
  }

  relations() {
    return {
      salesOrder: SalesOrder,
      articleType: ArticleType,
      project: Project,
      exactSalesOrderLine: ExactSalesOrderLine,
      navisionSalesOrderLine: NavisionSalesOrderLine,
      navisionProjectLine: NavisionProjectLine,
      outShipmentLines: OutShipmentLineStore,
      resourceAllocations: ResourceAllocationStore,
    }
  }

  casts() {
    return {
      productionDueDate: Casts.date,
      deliveryDate: Casts.date,
    }
  }

  /**
   * Whether there is at least one BatchType 'sell' for this line's article,
   * which is necessary for creating OutShipment orders.
   */
  @computed get hasSellProcess() {
    return this.articleType.batchTypes.filter(({ type }) => type === 'sell').length > 0
  }

}

export class SalesOrderLineStore extends Store {
  static backendResourceName = 'sales_order_line'
  Model = SalesOrderLine
}
