import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExperimentStore } from './Experiment'
import { ExperimentMeasurementStore } from './ExperimentMeasurement'

export class ExperimentTreatment extends Model {
    static backendResourceName = 'experiment_treatment'
    @observable id = null
    @observable name = ''
    @observable amountOfPlants = 0

    relations() {
      return {
        experiments: ExperimentStore,
        measurements: ExperimentMeasurementStore,
      }
    }
  }

  export class ExperimentTreatmentStore extends Store {
    Model = ExperimentTreatment
    static backendResourceName = 'experiment_treatment'
  }
