import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { BusinessRelation } from './BusinessRelation'

export class ExactAccount extends Model {
  static backendResourceName = 'exact_account'

  @observable id = null
  @observable uuid = null
  @observable number = null
  @observable name = ''
  @observable accountManager = ''
  @observable searchCode = ''

  relations() {
    return {
      businessRelation: BusinessRelation,
    }
  }
}

export class ExactAccountStore extends Store {
  static backendResourceName = 'exact_account'
  Model = ExactAccount
}
