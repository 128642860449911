import { observable, computed } from 'mobx'
import { omit, isArray } from 'lodash'
import { Model, Store, Casts } from 'store/Base'
import { PUBLIC_URL } from 'helpers'
import { Operator, OperatorStore } from './Operator'
import { WorkScheduleStore } from './WorkSchedule'
import { LeaveSlotStore } from './LeaveSlot'
import { LeaveCalendarStore } from './LeaveCalendar'
import { ExtraLeaveBalanceStore } from './ExtraLeaveBalance'
import { UserArticleTypePermissionScopeStore } from './UserArticleTypePermissionScope'
import { Stakeholder } from './Stakeholder'
import { camelCase } from 'lodash';

export class Permission extends Model {
  static backendResourceName = 'permission'

  @observable id = null
  @observable app = ''
  @observable codename = ''
  @observable permissions = []
}

export class PermissionStore extends Store {
  Model = Permission
  static backendResourceName = 'permission'
}

export class Group extends Model {
  static backendResourceName = 'group'

  @observable id = null
  @observable name = ''

  relations() {
    return {
      permissions: PermissionStore,
    }
  }
}

export class GroupStore extends Store {
  Model = Group
  static backendResourceName = 'group'
}

export class User extends Model {
  static backendResourceName = 'user'
  static omitFields = ['clockInTime', 'leaveBalance']

  @observable id = null
  @observable email = ''
  @observable firstName = ''
  @observable lastName = ''
  @observable password = ''
  @observable isSuperuser = false
  @observable avatar = null
  @observable deleted = false
  @observable active = false
  @observable dateJoined = null
  @observable clockInTime = null
  @observable leaveBalance = {}
  @observable editOpenProductionOrder = 'all'
  @observable releaseProductionOrder = 'all'
  @observable editReleasedProductionOrder = 'all'
  @observable telegramUsername = ''

  // TODO: currently hardcoded. Should be a select. Check if user creation still works after changing this
  @observable language = 'en'

  getUrl() {
    return `/personel/user/${this.id}/edit`
  }

  relations() {
    return {
      manager: User,
      operator: Operator,
      managedOperators: OperatorStore,
      groups: GroupStore,
      workSchedules: WorkScheduleStore,
      leaveSlots: LeaveSlotStore,
      leaveCalendars: LeaveCalendarStore,
      extraLeaveBalances: ExtraLeaveBalanceStore,
      articleTypePermissionScopes: UserArticleTypePermissionScopeStore,
      stakeholder: Stakeholder,
    }
  }

  casts() {
    return {
      avatar: {
        parse(attr, value) {
          if (value) {
            return PUBLIC_URL + value
          }

          return null
        },
        toJS(attr, value) {
          return value
        },
      },
      dateJoined: Casts.datetime,
      clockInTime: Casts.datetime,
    }
  }

  @computed
  get fullName() {
    if (this.firstName || this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }
    return this.email
  }

  @computed get permissions() {
    const perms = []

    this.groups.forEach((group) =>
      group.permissions.forEach((highLevelPermission) => {
        perms.push(`${highLevelPermission.app}.${highLevelPermission.codename}`)

        highLevelPermission.permissions.forEach((p) => perms.push(`${p.name}:${p.scope}`))
      })
    )

    return perms
  }

  masquerade() {
    return this.api.post(`/user/${this.id}/masquerade/`).then(() => {
      window.location = '/'
    })
  }

  resetRequest(username) {
    return this.api.post(`/user/reset_request/`, { username })
  }

  changePassword({ passwordOld, passwordNew }) {
    return this.api.put(`/user/change_password/`, {
      old_password: passwordOld,
      new_password: passwordNew,
    })
  }

  resetPassword({ id, password, resetCode }) {
    return this.api
      .put(`/user/${id}/reset_password/`, {
        password,
        reset_code: resetCode,
      })
      .then(() => {
        window.location = '/'
      })
  }

  hasPermission(permissions) {
    if (this.isSuperuser) {
      return true
    }
    if (!isArray(permissions)) {
      return this.permissions.includes(permissions)
    }
    return this.permissions.some((groupName) => permissions.includes(groupName))
  }

  @computed
  get groupNames() {
    return this.groups.map((group) => group.name)
  }

  inGroup(groups) {
    if (!isArray(groups)) {
      return this.groupNames.includes(groups)
    }
    return this.groupNames.some((groupName) => groups.includes(groupName))
  }

  toBackend(options = {}) {
    return omit(super.toBackend(options), 'password', 'active')
  }

  hasProductionOrderScope(scope, articleTypeId) {
    const value = this[scope]
    if (value === 'all') {
      return true
    } else if (value === 'some') {
      return this.articleTypePermissionScopes.filter((perm) => camelCase(perm.scope) === scope && perm.articleType.id === articleTypeId).length > 0
    } else if (value === 'none') {
      return false
    } else {
      return false
    }
  }
}

export class UserStore extends Store {
  Model = User
  static backendResourceName = 'user'
}
