import {Model, Store} from 'store/Base';
import {observable} from "mobx";
import { PlantationPositionAllocationStore } from "./PlantationPositionAllocation";

export class PlantationState extends Model {
    static backendResourceName = 'plantation_state';

    @observable id = null;
    @observable date = null;



    relations() {
        return {
            plantationPositionAllocations: PlantationPositionAllocationStore,
        };
    }
}

export class PlantationStateStore extends Store {
    Model = PlantationState;
    static backendResourceName = 'plantation_state';
}
