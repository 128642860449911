import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Batch } from 'store/Batch'
import { ExactItem } from 'store/ExactItem'
import { ExactPurchaseOrderLine } from 'store/ExactPurchaseOrderLine'
import { ExactGoodsReceipt } from 'store/ExactGoodsReceipt'

export class ExactGoodsReceiptLine extends Model {
  static backendResourceName = 'exact_goods_receipt_line'

  @observable id = null
  @observable uuid = null
  @observable notes = ''
  @observable quantityOrdered = null
  @observable quantityReceived = null
  @observable lineNumber = 0
  @observable created = null
  @observable modified = null

  relations() {
    return {
      batch: Batch,
      exactItem: ExactItem,
      exactPurchaseOrderLine: ExactPurchaseOrderLine,
      exactGoodsReceipt: ExactGoodsReceipt,
    }
  }

  casts() {
    return {
      created: Casts.datetime,
      modified: Casts.datetime,
    }
  }
}

export class ExactGoodsReceiptLineStore extends Store {
  Model = ExactGoodsReceiptLine
  static backendResourceName = 'exact_goods_receipt_line'
}
