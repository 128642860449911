import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { StorageLocation } from './StorageLocation'
import { ArticleType } from './ArticleType'
import { WarehouseTransfer } from './WarehouseTransfer'
import { ProductionRequest } from './ProductionRequest'

export class WarehouseTransferLine extends Model {
  static backendResourceName = 'warehouse_transfer_line'
  static idPrefix = 'TRL'
  static idColor = 'red'
  static idIcon = 'swap_horizontal_circle_white'

  @observable id = null
  @observable lineNumber = ''
  @observable createdDate = null
  @observable quantity = 0

  relations() {
    return {
      warehouseTransfer: WarehouseTransfer,
      articleType: ArticleType,
      storageLocationFrom: StorageLocation,
      storageLocationTo: StorageLocation,
      productionRequest: ProductionRequest,
    }
  }

  casts() {
    return {
      createdDate: Casts.datetime,
    }
  }
}

export class WarehouseTransferLineStore extends Store {
  Model = WarehouseTransferLine
  static backendResourceName = 'warehouse_transfer_line'
}
