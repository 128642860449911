import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Batch } from './Batch'

export class BatchUsage extends Model {
  static backendResourceName = 'batch_usage'

  @observable id = null
  @observable quantity = 0

  relations() {
    return {
      usingBatch: Batch,
      usedBatch: Batch,
    }
  }
}

export class BatchUsageStore extends Store {
  static backendResourceName = 'batch_usage'

  Model = BatchUsage
}
