import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionOrder } from 'store/ProductionOrder'
import { PurchaseOrderLine } from 'store/PurchaseOrderLine'
import { SalesOrderLine } from 'store/SalesOrderLine'

export class ResourceAllocation extends Model {
  static backendResourceName = 'resource_allocation'

  @observable id = null
  @observable quantity = 0

  relations() {
    return {
      productionOrder: ProductionOrder,
      purchaseOrderLine: PurchaseOrderLine,
      salesOrderLine: SalesOrderLine,
    }
  }
}

export class ResourceAllocationStore extends Store {
  static backendResourceName = 'resource_allocation'
  Model = ResourceAllocation
}
