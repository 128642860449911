import { observable, computed, action } from 'mobx'
import { Model, Store, Casts, subscribe } from 'store/Base'
import { ProcessVersion } from './ProcessVersion'
import { BatchStore } from './Batch'
import { InShipment } from './InShipment'
import { InShipmentLine } from './InShipmentLine'
import { OutShipment } from './OutShipment'
import { OutShipmentLine } from './OutShipmentLine'
import { ArticleType } from './ArticleType'
import { Step } from './Step'
import { ExactShopOrder } from './ExactShopOrder'
import { ProductionOrder } from './ProductionOrder'
import { WarehouseTransfer } from './WarehouseTransfer'
import { WarehouseTransferLine } from './WarehouseTransferLine'
import { Project } from './Project'
import { MaterialIssueStore } from './MaterialIssue'
import moment from 'moment'
import { StockCount } from './StockCount'
import { PlantationAllocationStore } from './PlantationAllocation'

function getWorkMinutes({ branches, steps }) {
  let time = 0
  for (const branch of branches) {
    time += getWorkMinutes(branch)
  }
  for (const step of steps) {
    if (step.type === 'multiplier') {
      time *= step.multiplierStep.multiplier
    } else {
      time += step.workMinutes
    }
  }
  return time
}

export class ProductionRequest extends Model {
  static backendResourceName = 'production_request'
  static idPrefix = 'WO'
  static idColor = 'teal'

  getUrl() {
    return `/operations/production-request/overview?.id=${this.id}`
  }

  getLink(props = {}) {
    return super.getLink({
      target: '_blank',
      ...props,
    })
  }

  getTarget() {
    return '_blank'
  }


  // getIdIcon() {
  //   const type = this.processVersion.batchType.type
  //   return TYPE_ICONS[type]
  // }


  constructor(...args) {
    super(...args)
    this.onPerformance = this.onPerformance.bind(this)
    this.onPerformanceReset = this.onPerformanceReset.bind(this)
    this.onSuperrequestAtSubprocessesChange = this.onSuperrequestAtSubprocessesChange.bind(this)
    this.onSubrequestsFinishedChange = this.onSubrequestsFinishedChange.bind(this)
  }

  @observable id = null
  @observable quantity = 0
  @observable startAt = null
  @observable endAt = null
  @observable startDate = null
  @observable endDate = null

  // Annotations
  @observable finished = false
  @observable quantityDone = 0
  @observable startedAt = null
  @observable endedAt = null
  @observable duration = 0
  @observable progress = 0
  @observable workMinutesTotal = 0
  @observable workMinutesDone = 0
  @observable subrequestsFinished = false
  @observable superrequestAtSubprocesses = false
  @observable growEndDate = null

  @computed
  get _labelContent() {
    return this.number
  }

  getWorkMinutesExpectedDone(processVersion) {
    return this.batches.models
      .flatMap((target) => target.performances.models)
      .map((performance) => processVersion.steps.get(performance.step.id).workMinutes)
      .reduce((a, b) => a + b, 0)
  }

  getWorkMinutesActualDone() {
    return this.batches.models
      .flatMap((target) => target.performances.models)
      .map((performance) => performance.createdAt.diff(performance.startedAt, 'm', true))
      .reduce((a, b) => a + b, 0)
  }

  getWorkMinutesTotal(steps) {
    return getWorkMinutes(steps) * this.quantity
  }

  @computed get done() {
    return this.quantityDone >= this.quantity
  }

  relations() {
    return {
      processVersion: ProcessVersion,
      batches: BatchStore,
      productionOrder: ProductionOrder,
      exactShopOrder: ExactShopOrder,
      inShipment: InShipment,
      inShipmentLine: InShipmentLine,
      outShipment: OutShipment,
      outShipmentLine: OutShipmentLine,
      warehouseTransfer: WarehouseTransfer,
      warehouseTransferLine: WarehouseTransferLine,
      project: Project,
      materialIssues: MaterialIssueStore,
      stockCount: StockCount,
      articleType: ArticleType,
      superrequest: ProductionRequest,
      allocations: PlantationAllocationStore
    }
  }

  casts() {
    return {
      startAt: Casts.tzDatetime,
      startDate: Casts.date,
      endDate: Casts.date,
      startedAt: Casts.datetime,
      endAt: Casts.tzDatetime,
      endedAt: Casts.datetime,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.quantity_done
    delete res.started_at
    delete res.ended_at
    delete res.duration
    delete res.progress
    delete res.finished
    delete res.work_minutes_total
    delete res.work_minutes_done
    delete res.subrequests_finished
    delete res.superrequest_at_subprocesses
    return res
  }

  subscribeToPerformances(options) {
    const performanceSubscription = subscribe(
      { type: 'performance', production_request: this.id },
      (performance) => this.onPerformance(performance, options),
    )
    const performanceResetSubscription = subscribe(
      { type: 'performance_reset', production_request: this.id },
      (performance) => this.onPerformanceReset(performance, options),
    )
    const superrequestAtSubprocessesChangeSubscription = subscribe(
      { type: 'superrequest_at_subprocesses_change', production_request: this.id },
      (change) => this.onSuperrequestAtSubprocessesChange(change, options),
    )
    const subrequestsFinishedChangeSubscription = subscribe(
      { type: 'subrequests_finished_change', production_request: this.id },
      (change) => this.onSubrequestsFinishedChange(change, options),
    )

    return {
      performanceSubscription,
      performanceResetSubscription,
      superrequestAtSubprocessesChangeSubscription,
      subrequestsFinishedChangeSubscription,
      unsubscribe: () => {
        performanceSubscription.unsubscribe()
        performanceResetSubscription.unsubscribe()
        superrequestAtSubprocessesChangeSubscription.unsubscribe()
        subrequestsFinishedChangeSubscription.unsubscribe()
      },
    }
  }

  @action onPerformance({ data: { step, batch, operator, performed_at, details = [], production_request } }, { removeFinalized = false } = {}) {
    const batchModel =
      this.batches.get(batch.id) ||
      this.batches.add({
        id: batch.id,
        serialNumber: batch.serial_number,
      })

    this.quantityDone = production_request.quantity_done
    this.workMinutesDone = production_request.work_minutes_done

    if (removeFinalized && batch.finalized_at !== null) {
      this.batches.remove(batchModel);
      return;
    }

    batchModel.quantity = batch.quantity
    batchModel.variableQuantity = batch.variable_quantity
    batchModel.quantityRemaining = batch.quantity_remaining
    batchModel.finished = batch.finished
    batchModel.lastPerformance = Casts.datetime.parse('lastPerformance', performed_at)

    for (const usage of batch.usages) {
      const usageModel = batchModel.batchUsings.add({ quantity: usage.quantity })
      usageModel.usedBatch = this.batches.get(usage.batch)
      usageModel.usedBatch.quantityRemaining -= usage.quantity
    }

    if (batchModel.performances !== undefined) {
      const performance = batchModel.performances.add({
        step: {
          id: step.id,
        },
        operator: {
          id: operator.id,
          firstName: operator.first_name,
          lastName: operator.last_name,
        },
        createdAt: performed_at,
        details: details.map((detail) => ({
          id: detail.id,
          components: detail.components.map((batch) => ({
            id: batch.id,
            serialNumber: batch.serialNumber,
            batchUsings: batch.batch_usings.map((usage) => ({
              id: usage.id,
              quantity: usage.quantity,
              usedBatch: {
                id: usage.used_batch.id,
                serialNumber: usage.used_batch.serial_number,
                quantity: usage.used_batch.quantity,
              },
            })),
          })),
        })),
      })

      if (performance.details.length > 0) {
        performance.details.params['.id:in'] = performance.details.map(({ id }) => id).join(',')
        performance.details.fetch()
      }
    }

    batchModel.setInput('lastStep', new Step({ id: step.id }))
    batchModel.setInput('finished', batch.finished)
    batchModel.setInput('finalizedAt', Casts.datetime.parse('finalizedAt', batch.finalized_at))
  }

  @action onPerformanceReset({ data: { target, scrapped } }) {
    const finished = scrapped.length > 0 && this.batches.filter(({ finished }) => finished)

    for (const { id, scrap_reason } of scrapped) {
      const batch = this.batches.get(id)
      if (batch.scrapReason === null) {
        for (const usage of batch.batchUsings.models) {
          const usedBatch = this.batches.get(usage.usedBatch.id)
          usedBatch.quantityRemaining += usage.quantity
        }
      }
      batch.scrapReason = scrap_reason

      for (let i = 0; i < finished.length; i++) {
        if (finished[i].batchUsings.models.some((usage) => usage.usedBatch.id === id)) {
          finished[i].finished = false
          finished.splice(i, 1)
          i--
        }
      }
    }

    const targetBatch = this.batches.get(target.id)
    targetBatch.setInput('lastStep', new Step({ id: target.last_step }))
    targetBatch.setInput('finished', false)
    targetBatch.setInput('finalizedAt', null)
  }

  @action onSubrequestsFinishedChange({ data: { subrequests_finished } }) {
    this.subrequestsFinished = subrequests_finished
  }

  @action onSuperrequestAtSubprocessesChange({ data: { superrequest_at_subprocesses } }) {
    this.superrequestAtSubprocesses = superrequest_at_subprocesses
  }

  async fastForward(days) {
    await this.api.post('production_request/fast_forward/', { days })
    return await this.fetch()
  }
}

export class ProductionRequestStore extends Store {
  Model = ProductionRequest
  static backendResourceName = 'production_request'

  constructor(...args) {
    super(...args)
    this.onPerformance = this.onPerformance.bind(this)
    this.onPerformanceReset = this.onPerformanceReset.bind(this)
    this.onSuperrequestAtSubprocessesChange = this.onSuperrequestAtSubprocessesChange.bind(this)
    this.onSubrequestsFinishedChange = this.onSubrequestsFinishedChange.bind(this)
  }

  subscribeToPerformances(production_request = '*', options) {
    const performanceSubscription = subscribe(
      { type: 'performance', production_request },
      (performance) => this.onPerformance(performance, options),
    )
    const performanceResetSubscription = subscribe(
      { type: 'performance_reset', production_request },
      (performance) => this.onPerformanceReset(performance, options),
    )
    const superrequestAtSubprocessesChangeSubscription = subscribe(
      { type: 'superrequest_at_subprocesses_change', production_request },
      (change) => this.onSuperrequestAtSubprocessesChange(change, options),
    )
    const subrequestsFinishedChangeSubscription = subscribe(
      { type: 'subrequests_finished_change', production_request },
      (change) => this.onSubrequestsFinishedChange(change, options),
    )

    return {
      performanceSubscription,
      performanceResetSubscription,
      superrequestAtSubprocessesChangeSubscription,
      subrequestsFinishedChangeSubscription,
      unsubscribe: () => {
        performanceSubscription.unsubscribe()
        performanceResetSubscription.unsubscribe()
        superrequestAtSubprocessesChangeSubscription.unsubscribe()
        subrequestsFinishedChangeSubscription.unsubscribe()
      },
    }
  }

  onPerformance(performance, options) {
    const productionRequest = this.get(performance.data.batch.production_request)
    if (productionRequest) {
      productionRequest.onPerformance(performance, options)
    }
  }

  onPerformanceReset(performance, options) {
    const productionRequest = this.get(performance.data.target.production_request)
    if (productionRequest) {
      productionRequest.onPerformanceReset(performance, options)
    }
  }

  onSubrequestsFinishedChange(change, options) {
    const productionRequest = this.get(change.data.production_request)
    if (productionRequest) {
      productionRequest.onSubrequestsFinishedChange(change, options)
    }
  }

  onSuperrequestAtSubprocessesChange(change, options) {
    const productionRequest = this.get(change.data.production_request)
    if (productionRequest) {
      productionRequest.onSuperrequestAtSubprocessesChange(change, options)
    }
  }
}
