import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { BillOfMaterialVersion } from './BillOfMaterialVersion'

export class BillOfMaterialItem extends Model {
  static backendResourceName = 'bill_of_material_item'

  @observable id = null
  @observable description = ''
  @observable quantity = '0'
  @observable backflush
  @observable number

  relations() {
    return {
      articleType: ArticleType,
      billOfMaterialVersion: BillOfMaterialVersion,
    }
  }
}

export class BillOfMaterialItemStore extends Store {
  Model = BillOfMaterialItem
  static backendResourceName = 'bill_of_material_item'
}
