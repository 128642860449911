import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionLineVersionStore } from './ProductionLineVersion'
import { ArticleTypeStore } from './ArticleType'
import { LeaveCalendarStore } from './LeaveCalendar'
import { LeaveSlotStore } from './LeaveSlot'
import { Factory } from './Factory'
import { PlantationAllocationStore } from './PlantationAllocation'
import { ScriptAllocationStore } from './ScriptAllocation'
import { UnitStore } from './Unit'
import { ExperimentChamberStore } from './ExperimentChamber'

export const PRODUCTION_TYPES = ['production']
export const PLANTATION_TYPES = ['multilayer', 'highwire', 'rnd multilayer', 'rnd highwire']
export const TYPES = [...PRODUCTION_TYPES, ...PLANTATION_TYPES]

export const RND_TYPES = ['rnd multilayer', 'rnd highwire']
export const GROWER_TYPES = ['multilayer', 'highwire']
export const RND_CHAMBER_AREAS = {
  'rnd multilayer': {
    '45': { areaNumber: 1 },
    '44': { areaNumber: 2 },
    '43': { areaNumber: 3 },
    '42': { areaNumber: 4 },
    '39': { areaNumber: 5 },
    '40': { areaNumber: 6 },
    '41': { areaNumber: 7 },
    '38': { areaNumber: 8 },
    '37': { areaNumber: 9 },
    '46': { areaNumber: 10 },

  },
  'rnd highwire': {
    '59': { areaNumber: 11 },
    '50': { areaNumber: 12 },
    '51': { areaNumber: 13 },
    '52': { areaNumber: 14 },
    '53': { areaNumber: 15 },
    '54': { areaNumber: 16 },
    '55': { areaNumber: 17 },
    '56': { areaNumber: 18 },
    '57': { areaNumber: 19 },
    '58': { areaNumber: 20 },
    '48': { areaNumber: 21 },
  }
}

export const PLANTATION_AREA = {
  '45': { areaNumber: 1 },
  '44': { areaNumber: 2 },
  '43': { areaNumber: 3 },
  '42': { areaNumber: 4 },
  '39': { areaNumber: 5 },
  '40': { areaNumber: 6 },
  '41': { areaNumber: 7 },
  '38': { areaNumber: 8 },
  '37': { areaNumber: 9 },
  '46': { areaNumber: 10 },
  '59': { areaNumber: 11 },
  '50': { areaNumber: 12 },
  '51': { areaNumber: 13 },
  '52': { areaNumber: 14 },
  '53': { areaNumber: 15 },
  '54': { areaNumber: 16 },
  '55': { areaNumber: 17 },
  '56': { areaNumber: 18 },
  '57': { areaNumber: 19 },
  '58': { areaNumber: 20 },
  '48': { areaNumber: 21 },
  '35': { areaNumber: 1 },
  '2': { areaNumber: 1 }, //highwire
  '1': { areaNumber: 1 }, //for tests
  '3': { areaNumber: 1 }, //for tests
  '4': { areaNumber: 1 }, //for tests
  '8': { areaNumber: 1 }, //for tests
  '13': { areaNumber: 11 }, //for tests
}

export class ProductionLine extends Model {
  static backendResourceName = 'production_line'

  @observable id = null
  @observable name = ''
  @observable color = 'red'
  @observable type = 'production'
  @observable cultivationArea = null
  @observable ordering = 0

  @observable latestVersion = 1
  @observable newestVersion = 1

  @computed get _newestVersion() {
    return this.versions.find((v) => v.version === this.newestVersion)
  }

  @computed get _latestVersion() {
    return this.versions.find((v) => v.version === this.latestVersion)
  }

  getUrl() {
    return `/assets/production-line/${this.id}/edit`
  }

  allocate(...args) {
    return this.api.post(`${this.factory.url}allocate/${this.id}/`, ...args)
  }

  getDataFromDataLake(area, layer){
    return this.wrapPendingRequestCount(this.api.get(`${this.url}datalake/`, {'area': PLANTATION_AREA[area]?.areaNumber, 'layer': layer}))
  }

  getDataFromGenericDataLakeApi(...args){
    return this.wrapPendingRequestCount(this.api.post(`${this.url}generic_datalake_api/`, ...args))
  }

  relations() {
    return {
      versions: ProductionLineVersionStore,
      articleTypes: ArticleTypeStore,
      leaveCalendars: LeaveCalendarStore,
      leaveSlots: LeaveSlotStore,
      factory: Factory,
      allocations: PlantationAllocationStore,
      scriptAllocations: ScriptAllocationStore,
      units: UnitStore,
      experimentChambers: ExperimentChamberStore
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)

    delete res.latest_version
    delete res.newest_version
    delete res.utc_offset

    return res
  }
}

export class ProductionLineStore extends Store {
  Model = ProductionLine
  static backendResourceName = 'production_line'
}
