import { ItemButton } from 'component/AdminOverview'
import { print, getPrinters, getPrinterType } from 'helpers/print'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Modal, Button, Form } from 'semantic-ui-react'
import { IconButton } from 'spider/semantic-ui/Button'
import RightDivider from 'spider/component/RightDivider'
import { t } from '../i18n'
import { TargetNumberInput } from 'spider/semantic-ui/Target'
import PrintersList from 'component/PrintersList'
import getGlobalValue from 'helpers/getGlobalValue'

@observer
export default class PrintButtonModal extends Component {
  static propTypes = {
    getInstructions: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    trigger: PropTypes.node,
  }

  static defaultProps = {
    trigger: null,
  }

  @observable print = null
  @observable copies = 1

  render() {
    const { getInstructions, title, trigger, ...props } = this.props
    return (
      <Modal closeIcon
        size="small"
        trigger={trigger !== null ? trigger :
          <ItemButton
            icon="print"
            label={t('printModal.title', { item: title })}
            {...props}
          />
        }
        open={this.print !== null}
        onOpen={async () => {
          const [printerType, allPrinters, instructions] = await Promise.all([
            getGlobalValue('printer_model'),
            getPrinters(),
            getInstructions(),
          ])

          const printers = allPrinters.filter((printer) => getPrinterType(printer) === printerType)
          const printer = printers.length === 1 ? printers[0] : null

          this.print = { printerType, instructions, printers, printer }
        }}
        onClose={() => this.print = null}
      >
        <Modal.Header>{t('printModal.title', { item: title })}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>{t('printModal.selectPrinter')}</label>
              {this.print === null ? (
                null
              ) : this.print.printers.length === 0 ? (
                <>
                  {t('printModal.noPrintersAvailable')}
                  <IconButton name="refresh" onClick={async () => {
                    this.print.printers = (await getPrinters()).filter((printer) => getPrinterType(printer) === this.print.printerType)
                  }} />
                </>
              ) : (
                <PrintersList
                  printers={this.print.printers}
                  onSelect={(printer) => this.print.printer = printer}
                />
              )}
            </Form.Field>
            <TargetNumberInput data-test-number-of-copies
              label={t('printModal.copies')}
              value={this.copies.toString()}
              onChange={(value) => {
                if (value !== ''){
                  this.copies = parseInt(value)
                }
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button data-test-print-button primary
            labelPosition="left" icon="print"
            content={t('printModal.printButton')}
            disabled={this.print === null || this.print.printer === null}
            onClick={async () => {
              await print(this.print.printer, this.print.instructions, {copies:this.copies})
              this.print = null
            }}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
