import { computed } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Content } from 're-cy-cle'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button, Header } from 'semantic-ui-react'
import RightDivider from 'spider/component/RightDivider'
import styled from 'styled-components'

const PageContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

const TabsContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  position: relative;

  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
  padding: 25px 25px 0;
  margin: 0 auto;

  border-bottom: 1px solid #d8d8d8;

  > .ui.header {
    margin-bottom: 0.5rem !important;
  }
`

const Tab = styled(Button)`
  margin-bottom: -1px !important;
  margin-right: 0.75rem !important;
  &:last-child {
    margin-right: 0 !important;
  }

  /* More selectors so that this takes precedence */
  &.ui.button.ui.button {
    padding-left: 1.25em !important;
    padding-right: 1.25em !important;
    &.icon {
      padding-left: 2.75em !important;
    }
  }

  border: 1px solid transparent !important;
  border-bottom-width: 0 !important;
  background-color: transparent !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  > i.icon {
    left: 0.25em !important;
    background-color: transparent !important;
  }
  &.active {
    background-color: ${({ bgColor = '#FFF' }) => bgColor} !important;
    border-color: #d8d8d8 !important;
  }
  &:hover {
    background-color: #d8d8d8 !important;
  }

  ${({ error }) =>
    error ? `
      background-color: red !important;
      padding-bottom: 10px;
      color: white !important;

      &.active {
        background-color: red !important;
        color: white !important;
      }
      &:hover {
        background-color: darkred !important;
        color: white !important;
      }
    ` : ``
  }

  transition: color 300ms ease, background-color 300ms ease, border-color 300ms ease !important;
`

const TabContainer = styled(Content)`
  main {
    max-width: ${({ maxWidth }) => maxWidth};
    ${({ noPadding }) =>
      noPadding
        ? `
            padding: 0;
            height: 100%;
        `
        : ``}
  }
`

@withRouter
@observer
export default class Tabs extends Component {
  static propTypes = {
    tab: PropTypes.string,
    match: PropTypes.object.isRequired,
    header: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    defaultTab: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string,
        render: PropTypes.string.isRequired,
        noPadding: PropTypes.bool,
      }).isRequired
    ).isRequired,
    maxWidth: PropTypes.string,
    beforeHeader: PropTypes.node,
    afterHeader: PropTypes.node,
    beforeTabs: PropTypes.node,
    afterTabs: PropTypes.node,
    beforeTab: PropTypes.node,
    afterTab: PropTypes.node,
  }

  static defaultProps = {
    maxWidth: '900px',
    beforeHeader: null,
    afterHeader: null,
    beforeTabs: null,
    afterTabs: null,
    beforeTab: null,
    afterTab: null,
  }

  constructor(...args) {
    super(...args)
    this.renderTab = this.renderTab.bind(this)
  }

  @computed get tab() {
    const { match, tabs, baseUrl, defaultTab } = this.props
    if (this.props.tab) {
      return this.props.tab
    }

    if (!match.url.startsWith(baseUrl)) {
      return defaultTab
    }

    const tab = match.url.slice(baseUrl.length)
    return tabs.some(({ key }) => key === tab) ? tab : defaultTab
  }

  renderTab({ key, label, icon, ...props }) {
    const { baseUrl } = this.props

    delete props.noPadding

    return (
      <Tab
        key={key}
        icon={icon}
        labelPosition={icon && 'left'}
        content={label}
        active={key === this.tab}
        as={Link}
        to={baseUrl + key}
        {...props}
      />
    )
  }

  render() {
    const { header, tabs, maxWidth, beforeHeader, afterHeader, beforeTabs, afterTabs, beforeTab, afterTab } = this.props

    const tab = tabs.find(({ key }) => key === this.tab)

    return (
      <PageContainer>
        <TabsContainer maxWidth={maxWidth}>
          {beforeHeader}
          <Header as="h1">{header}</Header>
          {afterHeader}
          <RightDivider />
          {beforeTabs}
          {tabs.map(this.renderTab)}
          {afterTabs}
        </TabsContainer>
        <TabContainer maxWidth={maxWidth} noPadding={tab.noPadding}>
          {beforeTab}
          {tab.render()}
          {afterTab}
        </TabContainer>
      </PageContainer>
    )
  }
}
