import { computed, observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { User } from 'store/User'
import { ExperimentChamberStore } from './ExperimentChamber'
import { ExperimentMeasurementStore } from './ExperimentMeasurement'
import { ExperimentTreatmentStore } from './ExperimentTreatment'
import { ExperimentVariableStore } from './ExperimentVariable'
import { ExperimentVarietyStore } from './ExperimentVariety'
import moment from 'moment'
import { ExperimentBarcodeStore } from './ExperimentBarcode'

export const CROP_TYPE_LEAFY = 'leafy'
export const CROP_TYPE_BINDING = 'binding'
export const CROP_TYPES = [CROP_TYPE_LEAFY, CROP_TYPE_BINDING]

export class Experiment extends Model {
    static backendResourceName = 'experiment'

    // DL -> Tracy
    @observable experimentId = null
    @observable experimentName = ''
    @observable experimentStatus = 'Pending'
    @observable experimentDue = null
    @observable experimentDeleted = false

    // @observable created = null;
    // @observable dependencyExperimentId = null;
    // @observable description = null;
    // @observable groupId = null;
    // @observable notes = null;
    // @observable projectId = null;
    // @observable signatureStatus = null;
    // @observable statusChanged = null;
    // @observable studyId = null;
    // @observable subgroupId = null;
    // @observable template = null;
    // @observable userId = null;
    // @observable workflowStepId = null;
    // @observable status = {}

    // Tracy -> DL
    @observable id = null
    @observable cropType = null
    @observable lastSync = null

    relations() {
      return {
        owner: User,
        experimentChambers: ExperimentChamberStore,
        measurements: ExperimentMeasurementStore,
        treatments: ExperimentTreatmentStore,
        variables: ExperimentVariableStore,
        varieties: ExperimentVarietyStore,
        barcodes: ExperimentBarcodeStore
      }
    }

    @computed get experimentDuePassed() {
      return moment(this.experimentDue).isBefore(moment())
    }
  }

  export class ExperimentStore extends Store {
    Model = Experiment
    static backendResourceName = 'experiment'
  }
