import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Experiment } from 'store/Experiment';
import { ExperimentMeasurementValueStore } from './ExperimentMeasurementValue'
import { ExperimentTreatmentStore } from './ExperimentTreatment'
import { ExperimentVariableStore } from './ExperimentVariable'
import { ExperimentVarietyStore } from './ExperimentVariety'
import { User } from './User'

export class ExperimentMeasurement extends Model {
    static backendResourceName = 'experiment_measurement'
    @observable id = null
    @observable timestamp = null
    @observable status = 'active'
    @observable remarks = ''

    relations() {
      return {
        experiment: Experiment,
        measurementValues: ExperimentMeasurementValueStore,
        treatments: ExperimentTreatmentStore,
        variables: ExperimentVariableStore,
        varieties: ExperimentVarietyStore,
        addedBy: User,
      }
    }
  }

  export class ExperimentMeasurementStore extends Store {
    Model = ExperimentMeasurement
    static backendResourceName = 'experiment_measurement'
  }
