import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { StorageLocation } from './StorageLocation'
import { Warehouse } from './Warehouse.js'

export class ArticleTypeStorageLocation extends Model {
  static backendResourceName = 'article_type_storage_location'

  @observable id = null
  @observable stock = 0

  relations() {
    return {
      articleType: ArticleType,
      storageLocation: StorageLocation,
      warehouse: Warehouse,
    }
  }
}

export class ArticleTypeStorageLocationStore extends Store {
  Model = ArticleTypeStorageLocation
  static backendResourceName = 'article_type_storage_location'
}
