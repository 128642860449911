export default {
  color: {
    red: 'Rot',
    orange: 'Orange',
    yellow: 'Gelb',
    olive: 'Olivegrün',
    green: 'Grün',
    teal: 'Blaugrün',
    blue: 'Blau',
    violet: 'Violett',
    purple: 'Lila',
    pink: 'Rosa',
    brown: 'Braun',
    grey: 'Grau',
    black: 'Schwarz',
  },
  target: {
    TargetColorSelect: {
      label: 'Farbe',
    }
  }
}
