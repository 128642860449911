import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base';
import { ScriptAllocationStore } from 'store/ScriptAllocation'


export class Factory extends Model {
  static backendResourceName = 'factory'

  @observable id = null
  @observable name = ''
  @observable timezone = 'Europe/Amsterdam'
  @observable utcOffset = null
  @observable currentTime = null

  casts() {
    return {
      currentTime: Casts.momentTime,
    }
  }

  allocate(...args) {
    return this.api.post(`${this.url}allocate/`, ...args)
  }

  relations() {
    return {
      scriptAllocations: ScriptAllocationStore,
    }
  }
}

export class FactoryStore extends Store {
  static backendResourceName = 'factory'
  Model = Factory
}
