import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Integration } from './Integration'

export class SyncError extends Model {
  static backendResourceName = 'sync_error'

  @observable id = null
  @observable message = ''

  relations() {
    return {
      integration: Integration,
    }
  }
}

export class SyncErrorStore extends Store {
  static backendResourceName = 'sync_error'
  Model = SyncError
}
