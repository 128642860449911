import { t } from 'i18n';

// Stolen from re-cy-cle
// lodash's `camelCase` method removes dots from the string; this breaks mobx-spine
export const ACTION_DELAY = 300;

export const BOOL_OPTIONS = [
    { value: 'true', text: t('form.yes') },
    { value: undefined, text: t('form.either') },
    { value: 'false', text: t('form.no') },
];

export function snakeToCamel(s) {
    if (s.startsWith('_')) {
        return s;
    }
    return s.replace(/_\w/g, m => m[1].toUpperCase());
}

// lodash's `snakeCase` method removes dots from the string; this breaks mobx-spine
export function camelToSnake(s) {
    return s.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

export function getMoneyInputProps() {
    return {
        prefix: '€',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        alignRight: true,
        decimalLimit: 2,
    };
}

export function decimalToFloat(value) {
    if (typeof value !== 'string') {
        return null;
    }
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export function setMoneyForBackend(value, decimals=2) {
    if (typeof value !== 'string') {
        return 0;
    }

    const parsed = decimalToFloat(value);

    return isFinite(parsed) ? Math.round(parsed * Math.pow(10, decimals)) : 0;
}

let moneyFormat = null;

export function formatMoney(value, decimals = 2) {
    if (!moneyFormat) {
        moneyFormat = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'
        });
    }

    // Better money formatter, which prefixed the euro symbol. We're not yet
    // ready for this...
    return moneyFormat.format(value / Math.pow(10, decimals)).replace('€ ', '€');
}

export function getMoneyForUser(value, decimals=2) {
    if (typeof value !== 'number') {
        return null;
    }

    return (value / Math.pow(10, decimals)).toFixed(decimals).replace('.', ',');
}

/* eslint-disable */
/* Ignore robwu_nl magic, in robwu_nl we trust. */
/* globals _ */
var toString = Object.prototype.toString;
var eq = function(a, b, aStack, bStack) {
    // Identical objects are equal. `0 === -0`, but they aren't identical.
    // See the [Harmony `egal` proposal](http://wiki.ecmascript.org/doku.php?id=harmony:egal).
    if (a === b) return a !== 0 || 1 / a === 1 / b;
    // A strict comparison is necessary because `null == undefined`.
    if (a == null || b == null) return a === b; // jshint ignore:line
    // Unwrap any wrapped objects.
    if (a instanceof _) a = a._wrapped;
    if (b instanceof _) b = b._wrapped;
    // Compare `[[Class]]` names.
    var className = toString.call(a);
    if (className !== toString.call(b)) return false;
    switch (className) {
        // Strings, numbers, regular expressions, dates, and booleans are compared by value.
        case '[object RegExp]':
            // RegExps are coerced to strings for comparison (Note: '' + /a/i === '/a/i')
        case '[object String]':
            // Primitives and their corresponding object wrappers are equivalent; thus, `"5"` is
            // equivalent to `new String("5")`.
            return '' + a === '' + b;
        case '[object Number]':
            // `NaN`s are equivalent, but non-reflexive.
            // Object(NaN) is equivalent to NaN
            if (+a !== +a) return +b !== +b;
            // An `egal` comparison is performed for other numeric values.
            return +a === 0 ? 1 / +a === 1 / b : +a === +b;
        case '[object Date]':
        case '[object Boolean]':
            // Coerce dates and booleans to numeric primitive values. Dates are compared by their
            // millisecond representations. Note that invalid dates with millisecond representations
            // of `NaN` are not equivalent.
            return +a === +b;
    }

    var areArrays = className === '[object Array]';
    if (!areArrays) {
        if (typeof a !== 'object' || typeof b !== 'object') return false;

        // Objects with different constructors are not equivalent, but `Object`s or `Array`s
        // from different frames are.
        var aCtor = a.constructor, bCtor = b.constructor;
        if (aCtor !== bCtor && !(_.isFunction(aCtor) && aCtor instanceof aCtor &&
                                 _.isFunction(bCtor) && bCtor instanceof bCtor) &&
                ('constructor' in a && 'constructor' in b)) {
            return false;
        }
    }
    // Assume equality for cyclic structures. The algorithm for detecting cyclic
    // structures is adapted from ES 5.1 section 15.12.3, abstract operation `JO`.

    // Initializing stack of traversed objects.
    // It's done here since we only need them for objects and arrays comparison.
    aStack = aStack || [];
    bStack = bStack || [];
    var length = aStack.length;
    while (length--) {
        // Linear search. Performance is inversely proportional to the number of
        // unique nested structures.
        if (aStack[length] === a) return bStack[length] === b;
    }

    // Add the first object to the stack of traversed objects.
    aStack.push(a);
    bStack.push(b);

    // Recursively compare objects and arrays.
    if (areArrays) {
        var aset = toSet(a, aStack);
        var bset = toSet(b, bStack);
        // Compare set sizes to determine if a deep comparison is necessary.
        if (aset.length !== bset.length) {
            aStack.pop();
            bStack.pop();
            return false;
        }
        length = aset.length;
        // Deep compare the contents, ignoring non-numeric properties.
        outerloop: for (var i = 0; i < length; ++i) {
            var val = aset[i];
            for (var j = 0; j < length; ++j) {
                if (eq(bset[j], val, bStack, aStack)) {
                    continue outerloop;
                }
            }
            aStack.pop();
            bStack.pop();
            return false;
        }
        aStack.pop();
        bStack.pop();
        return true;
    } else {
        // Deep compare objects.
        var keys = _.keys(a), key;
        length = keys.length;
        // Ensure that both objects contain the same number of properties before comparing deep equality.
        if (_.keys(b).length !== length) {
            aStack.pop();
            bStack.pop();
            return false;
        }
        while (length--) {
            // Deep compare each member
            key = keys[length];
            if (!(_.has(b, key) && eq(a[key], b[key], aStack, bStack))) {
                aStack.pop();
                bStack.pop();
                return false;
            }
        }
    }
    // Remove the first object from the stack of traversed objects.
    aStack.pop();
    bStack.pop();
    return true;
};
/* eslint-enable */

// Convert an array to an array without duplicates.
// Running time is quadratic in the size of a,
// multiplied by the complexity of the |eq| method of the elements in a.
var toSet = function(a, aStack) {
    var aset = [];
    aloop: for (var i = 0, ii = a.length; i < ii; ++i) {
        var val = a[i];
        for (var j = aset.length - 1; j >= 0; --j) {
            if (eq(aset[j], val, aStack, aStack)) {
                continue aloop;
            }
        }
        aset.push(val);
    }
   return aset;
};

export const isSimilar = function(a, b) {
    return eq(a, b);
};
