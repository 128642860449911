import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Fins } from './Fins'
import { Modbus } from './Modbus'

export class Value extends Model {
  static backendResourceName = 'value'
  @observable id = null
  @observable type = 'dec'
  @observable value = 0;
  @observable multiplicationFactor = 1
  @observable register = ''
  @observable name = ''

  getValue() {
    if (this.value === null){
      return null
    }
    else{
      return this.value / this.multiplicationFactor
    }
  }

  setValue(value) {
    this.value = value * this.multiplicationFactor
  }

  relations() {
    return {
      finsInstance: Fins,
      modbusInstance: Modbus,
    }
  }
}

export class ValueStore extends Store {
  Model = Value
  static backendResourceName = 'value'
}
