import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { ExactGoodsDeliveryLineStore } from './ExactGoodsDeliveryLine'
import { ExactGoodsReceiptLineStore } from './ExactGoodsReceiptLine'
import { ExactPurchaseOrderLineStore } from './ExactPurchaseOrderLine'
import { ExactSalesOrderLineStore } from './ExactSalesOrderLine'

export class ExactItem extends Model {
  static backendResourceName = 'exact_item'

  @observable id = null
  @observable code = ''
  @observable name = ''
  @observable classification = ''

  @observable isMakeItem = false
  @observable isPurchaseItem = false
  @observable isSalesItem = false
  @observable isStockItem = false

  // Annotations
  @observable hasArticleType = false
  @observable hasComponentType = false

  getUrl() {
    return `/assets/item/${this.id}/edit`
  }

  relations() {
    return {
      articleType: ArticleType,
      goodsDeliveryLines: ExactGoodsDeliveryLineStore,
      goodsReceiptLines: ExactGoodsReceiptLineStore,
      purchaseOrderLines: ExactPurchaseOrderLineStore,
      salesOrderLines: ExactSalesOrderLineStore,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)

    delete res.has_article_type
    delete res.has_component_type

    return res
  }
}

export class ExactItemStore extends Store {
  Model = ExactItem
  static backendResourceName = 'exact_item'
}
