import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExperimentMeasurementStore } from './ExperimentMeasurement';
import { ExperimentStore } from './Experiment';

export class ExperimentVariable extends Model {
    static backendResourceName = 'experiment_variable'
    @observable id = null;
    @observable name = '';

    relations() {
      return {
        experiments: ExperimentStore,
        measurements: ExperimentMeasurementStore,
      }
    }
  }

  export class ExperimentVariableStore extends Store {
    Model = ExperimentVariable
    static backendResourceName = 'experiment_variable'
  }
