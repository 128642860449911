import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Metafield } from './Metafield'
import { ArticleType } from './ArticleType'
import { LoadCarrier } from './LoadCarrier'

export class Metavalue extends Model {
  static backendResourceName = 'metavalue'

  @observable id = null

  @observable value = null
  @observable file = null

  relations() {
    return {
      metafield: Metafield,
      entryArticleType: ArticleType,
      entryLoadCarrier: LoadCarrier,
    }
  }
}

export class MetavalueStore extends Store {
  static backendResourceName = 'metavalue'

  Model = Metavalue
}
