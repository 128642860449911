import { Value } from "./Value"
import { Model, Store } from "./Base"
import { observable } from 'mobx'
import { PLCOption } from "./PlcOption"

export class PLCOptionValue extends Model {
    static backendResourceName = 'plc_option_value'
    @observable id = null
    @observable value = ''

    relations() {
        return {
            option: PLCOption,
            register: Value,
        }
    }
}

export class PLCOptionValueStore extends Store {
    Model = PLCOptionValue
    static backendResourceName = 'plc_option_value'
}
