import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactPurchaseOrderLineStore } from 'store/ExactPurchaseOrderLine'

export const STATUS_OPEN = 10
export const STATUS_PARTIAL = 20
export const STATUS_COMPLETE = 30
export const STATUS_CANCELED = 40
export const STATUSES = [STATUS_OPEN, STATUS_PARTIAL, STATUS_COMPLETE, STATUS_CANCELED]

export class ExactPurchaseOrder extends Model {
  static backendResourceName = 'exact_purchase_order'
  static idColor = 'red'
  static idIcon = 'exact'

  getUrl() {
    let purchaseUrl = this.api.baseUrl + this.url + 'exact/'
    purchaseUrl = purchaseUrl.replace(/([^:])(\/\/+)/g, '$1/')

    return purchaseUrl
  }

  getLink(props = {}) {
    return super.getLink({
      target: '_blank',
      ...props,
    })
  }

  @observable id = null
  @observable status = STATUS_OPEN
  @observable receiptStatus = STATUS_OPEN
  @observable number = ''
  @observable reference = ''
  @observable description = ''

  @computed
  get _labelContent() {
    return this.number
  }

  @computed
  get _status() {
    return t(`exactPurchaseOrder.field.status.value.${this.status}`)
  }

  relations() {
    return {
      lines: ExactPurchaseOrderLineStore,
    }
  }
}

export class ExactPurchaseOrderStore extends Store {
  static backendResourceName = 'exact_purchase_order'
  Model = ExactPurchaseOrder
}
