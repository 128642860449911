import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { IssueStore } from './Issue'


export class Stakeholder extends Model {
  static backendResourceName = 'stakeholder'

  @observable id = null
  @observable name = ''

  relations() {
    return {
      issues: IssueStore,
    }
  }
}

export class StakeholderStore extends Store {
  Model = Stakeholder
  static backendResourceName = 'stakeholder'
}
