import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Batch } from './Batch'
import { LoadCarrierWeighingStore } from 'store/LoadCarrierWeighing'
import { MetafieldStore } from 'store/Metafield'
import { MetavalueStore } from 'store/Metavalue'

export class LoadCarrier extends Model {
  static backendResourceName = 'load_carrier'
  static omitFields = ['reweighDate', 'needsReweighing']

  static TYPES = ['pallet_euro', 'pallet_pool', 'container', 'gitterbox', 'carton_box', 'stackbox']
  static WEIGHT_UNITS = ['kg', 'g', 'mg']

  static idColor = 'blue'
  static idIcon = 'dolly flatbed'

  @computed get _id() {
    return this.serialNumber
  }

  getUrl() {
    return `/assets/load-carrier/${this.id}/edit`
  }

  @observable id = null
  @observable name = ''
  @observable serialNumber = ''

  @observable weight = 0
  @observable weightUnit = 'kg'

  @observable reweighDays = null

  @observable reweighDate = null
  @observable needsReweighing = false

  relations() {
    return {
      batch: Batch,
      weighings: LoadCarrierWeighingStore,
      metafields: MetafieldStore,
      metavalues: MetavalueStore,
    }
  }

  casts() {
    return {
      reweighDate: Casts.date,
    }
  }
}

export class LoadCarrierStore extends Store {
  Model = LoadCarrier
  static backendResourceName = 'load_carrier'
}
