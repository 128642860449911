import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

const FormGroupContainer = styled.div`
    margin: 0.25em calc(-0.75em - 1px) 1em;
    padding: 0.75em;
    &:last-child {
        margin-bottom: 0;
    }
    border-radius: 0.25em;
    border: 1px solid rgba(34, 36, 38, 0.15);
    position: relative;
    > label:first-child {
        position: absolute;
        left: calc(0.75em / 0.75 - 0.25em);
        top: 0;
        transform: translateY(-50%);
        font-size: 0.75em;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.5);
        background-color: ${({ bgColor }) => bgColor};
        padding: 0 0.25em;
    }
    > .cy-form-group {
        margin-left: 0;
        margin-right: 0;
    }
`;

@observer
export default class FormGroup extends Component {
    static propTypes = {
        label: PropTypes.string,
        info: PropTypes.string,
        children: PropTypes.node,
        bgColor: PropTypes.string,
    };

    static defaultProps = {
        bgColor: '#EEE',
    };

    render() {
        const { label, info, children, ...props } = this.props;

        props.className = (
            props.className
            ? 'cy-form-group ' + props.className
            : 'cy-form-group'
        );

        let labelNode = label && (
            <label>{label}</label>
        );
        if (labelNode && info) {
            labelNode = (
                <Popup trigger={labelNode} content={info} />
            );
        }

        return (
            <FormGroupContainer {...props}>
                {labelNode}
                {children}
            </FormGroupContainer>
        );
    }
}
