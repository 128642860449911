import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { UnitStore } from './Unit'
import {Fins} from './Fins'
import {Modbus} from './Modbus'
import { WorkStation } from './WorkStation'
import { PLCChoice, PLCChoiceStore } from './PlcChoice'


export class PLC extends Model {
    static backendResourceName = 'plc'
    @observable id = null
    @observable name = ''
    @observable type = ''

    relations() {
        return {
            units: UnitStore,
            fins: Fins,
            modbus: Modbus,
            workStations: WorkStation,
            choices: PLCChoiceStore
        }
    }
}

export class PLCStore extends Store {
    Model = PLC
    static backendResourceName = 'plc'
}
