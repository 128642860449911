import { Model, Store } from "./Base"
import { observable } from 'mobx'
import { Step } from "./Step"
import { PLCChoice } from "./PlcChoice"
import { PLCOption } from "./PlcOption"

export class StepRegisterValue extends Model {
    static backendResourceName = 'step_register_value'
    @observable id = null

    relations() {
        return {
            step: Step,
            choice: PLCChoice,
            option: PLCOption,
        }
    }
}

export class StepRegisterValueStore extends Store {
    Model = StepRegisterValue
    static backendResourceName = 'step_register_value'
}
