import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Batch } from './Batch'
import { Step } from './Step'
import { Operator } from './Operator'
import { DetailStore } from './Detail'

export class Performance extends Model {
  static backendResourceName = 'performance'

  @observable id = null
  @observable startedAt = null
  @observable createdAt = null
  @observable reworkReason = null
  @observable reworked = null
  @observable printedTemplate = null
  @observable printedDocument = null

  relations() {
    return {
      batch: Batch,
      step: Step,
      operator: Operator,
      details: DetailStore,
    }
  }

  casts() {
    return {
      startedAt: Casts.datetime,
      createdAt: Casts.datetime,
    }
  }

  reset(reason) {
    return this.wrapPendingRequestCount(this.api.post(`${this.url}reset/`, { reason }))
  }
}

export class PerformanceStore extends Store {
  Model = Performance
  static backendResourceName = 'performance'
}
