import { PLC } from "./Plc"
import { PLCOption, PLCOptionStore } from "./PlcOption"
import { observable } from 'mobx'
import { Model, Store } from "./Base"
import { StepRegisterValueStore } from "./StepRegisterValue"

export class PLCChoice extends Model {
    static backendResourceName = 'plc_choice'
    @observable id = null
    @observable name = ''

    relations() {
        return {
            plc: PLC,
            options: PLCOptionStore,
            registerValues: StepRegisterValueStore,
        }
    }
}

export class PLCChoiceStore extends Store {
    Model = PLCChoice
    static backendResourceName = 'plc_choice'
}
