import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Experiment } from './Experiment';
import { ProductionLine } from './ProductionLine';
import { ExperimentTreatment } from './ExperimentTreatment';
import { ExperimentVariety } from './ExperimentVariety';

export class ExperimentBarcode extends Model {
    static backendResourceName = 'experiment_barcode'
    @observable id = null;
    @observable barcode = '';
    @observable plantNumber = null;

    relations() {
        return {
            experiment: Experiment,
            treatment: ExperimentTreatment,
            variety: ExperimentVariety,
        }
    }
  }

  export class ExperimentBarcodeStore extends Store {
    Model = ExperimentBarcode
    static backendResourceName = 'experiment_barcode'
  }
