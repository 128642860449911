import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { PLC } from './Plc'
import { ValueStore } from './Value'
import { ProductionLine } from './ProductionLine'


export class Unit extends Model {
    static backendResourceName = 'unit'

    @observable id = null
    @observable type = 'light'
    @observable identifier = ''
    @observable state = ''
    @observable location = []

    relations() {
        return {
            productionLine: ProductionLine,
            values: ValueStore,
        }
    }
}

export class UnitStore extends Store {
    Model = Unit
    static backendResourceName = 'unit'
}
