import React from 'react'
import { Model, Store } from 'store/Base'
import { observable } from 'mobx'
import { omit } from 'lodash'
import { Script } from './Script'
import { ScriptNotesStore } from 'store/ScriptNotes'

export const EDITABLE_STATUS = ['rnd', 'disapproved_by_tryout']

export class ScriptPackage extends Model {
    static backendResourceName = 'script_package'

    static STATUS = ['rnd', 'disapproved_by_tryout', 'tryout', 'approved_by_tryout', 'production']
    static STATUS_TO_TAG_NAME = {
        'rnd': 'R&D',
        'disapproved_by_tryout': 'Rejected',
        'tryout': 'Tryout',
        'approved_by_tryout': 'Approved',
        'production': 'Production',
    }
    static PLANTATION_AREAS = ['multilayer', 'highwire']
    static CHAPTERS = ['climate', 'nutritionStorage', 'irrigation', 'light']
    static CHAPTERS_TO_SCRIPT_TYPES = {
        'climate': 'environment',
        'nutritionStorage': 'nutrition_storage',
        'irrigation': 'nutrition_irrigation',
        'light': 'light',
    }

    static omitFields = ['title', 'subtitle'];

    @observable id = null
    @observable name = null
    @observable description = null
    @observable image = null
    @observable notes = null
    @observable suffix = null
    @observable status = 'rnd'
    @observable plantationArea = 'multilayer'
    @observable crop = null
    @observable duration = null //When duration is equal to null, we say the script package has a fixed duration
    @observable tryoutDescription = null
    @observable isArchived = false

    @observable title = null;
    @observable subtitle = null;

    relations() {
        return {
            climate: Script,
            nutritionStorage: Script,
            irrigation: Script,
            light: Script,
            scriptNotes: ScriptNotesStore,
        }
    }

    toBackend(...args) {
        var res = super.toBackend(...args)
        res = omit(res, 'visible_name');

        return res
    }

    async approve(data) {
        return await this.wrapPendingRequestCount(this.api.post(`${this.url}approve/`, data))
    }

    async disapprove(data) {
        return await this.wrapPendingRequestCount(this.api.post(`${this.url}disapprove/`, data))
    }
}

export class ScriptPackageStore extends Store {
    Model = ScriptPackage
    static backendResourceName = 'script_package'
}
