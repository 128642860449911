import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { computed, action, reaction, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Model, Store } from 'store/Base'
import { ArticleType } from 'store/ArticleType'
import { LoadCarrier } from 'store/LoadCarrier'
import { Metafield, MetafieldStore } from 'store/Metafield'
import { IconButton } from 'spider/semantic-ui/Button'
import { TargetTextInput, TargetSelect, TargetCheckbox, TargetMultiTextInput, TargetNumberInput, TargetTextArea, TargetImage, TargetRadioButtons } from 'spider/semantic-ui/Target'
import TargetSerialNumberFormat from 'component/TargetSerialNumberFormat'
import { Form, Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'
import PlaceholderImage from 'image/placeholder-image.png'
import { BOOL_OPTIONS } from 'helpers'


const LEVEL_PARENT = {
  article_type: null,
  load_carrier: null,
}

const LEVEL_ANCESTORS = {}

function addAncestors(level) {
  if (LEVEL_ANCESTORS[level] !== undefined) {
    return
  }
  const parent = LEVEL_PARENT[level]
  if (parent === undefined) {
    LEVEL_ANCESTORS[level] = []
  } else {
    addAncestors(parent)
    LEVEL_ANCESTORS[level] = [parent, ...LEVEL_ANCESTORS[parent]]
  }
}

Object.keys(LEVEL_PARENT).forEach(addAncestors)

function getLevel(model) {
  if (model instanceof ArticleType) {
    return 'article_type'
  } else if (model instanceof LoadCarrier) {
    return 'load_carrier'
  } else {
    throw new Error('unknown level')
  }
}

@observer
export class Metafields extends Component {
  static propTypes = {
    model: PropTypes.instanceOf(Model).isRequired,
  }

  @computed get level() {
    const { model } = this.props
    return getLevel(model)
  }

  @computed get descendants() {
    return ['article_type', 'load_carrier'].filter((level) => (
      level !== this.level &&
      !LEVEL_ANCESTORS[this.level].includes(level)
    ))
  }

  @computed get groups() {
    const { model } = this.props

    const groups = Object.fromEntries(this.descendants.map((level) => [level, []]))

    for (const metafield of model.metafields.models) {
      groups[metafield.entryLevel].push(metafield)
    }

    return groups
  }

  render() {
    const { model } = this.props
    return this.descendants.map((entryLevel) => (
      <Form.Field key={entryLevel}>
        <label>
          {t(`metafield.edit.entryLevel.${entryLevel}`, { count: this.groups[entryLevel].length })}
          <IconButton style={{ float: 'right' }} onClick={() => model.metafields.add({ definitionLevel: this.level, entryLevel })} />
        </label>
        {this.groups[entryLevel].map((metafield) => <MetafieldEdit metafield={metafield} />)}
      </Form.Field>
    ))
  }
}

const Indent = styled.div`
  border-left: 4px solid rgba(34, 36, 38, 0.15);
  padding: 0.75em 0 0.75em 1em;
  margin: -0.75em 0 1em;
  &:last-child {
    margin-bottom: 0;
  }
  > .fields:last-child {
    margin-bottom: 0 !important;
  }
`

@observer
export class MetafieldEdit extends Component {
  static propTypes = {
    metafield: PropTypes.instanceOf(Metafield).isRequired,
  }

  render() {
    const { metafield } = this.props
    return (
      <>
        <TargetTextInput target={metafield} name="name" />
        <TargetSelect
          target={metafield}
          name="type"
          options={Metafield.TYPES.map((type) => ({
            value: type,
            text: t(`metafield.field.type.value.${type}`),
          }))}
          onChange={action((type) => {
            metafield.setInput('type', type)

            let defaultValue = null

            if (metafield.type === 'text') {
              defaultValue = ''
            } else if (metafield.type === 'check') {
              defaultValue = false
            } else if (metafield.type === 'choice' && metafield.multiple) {
              defaultValue = []
            } else if (metafield.type === 'format') {
              defaultValue = ''
            }

            metafield.setInput('defaultValue', defaultValue)
            metafield.setInput('defaultFile', null)
          })}
        />
        {metafield.type === 'text' && (
          <Indent>
            <TargetCheckbox noLabel rightLabel target={metafield} name="textLong" />
          </Indent>
        )}
        {metafield.type === 'choice' && (
          <Indent>
            <TargetMultiTextInput target={metafield} name="choiceOptions" />
            <TargetCheckbox noLabel rightLabel target={metafield} name="choiceMultiple" />
          </Indent>
        )}
        {metafield.type === 'measure' && (
          <Indent>
            <Form.Group widths="equal">
              <TargetNumberInput allowDecimal target={metafield} name="measureMin" />
              <TargetNumberInput allowDecimal target={metafield} name="measureMax" />
            </Form.Group>
          </Indent>
        )}
        {metafield.type === 'format' && (
          <Indent>
            <TargetSerialNumberFormat allowAnything target={metafield} name="formatFormat" />
          </Indent>
        )}
        <TargetCheckbox noLabel rightLabel target={metafield} name="default" />
        {metafield.default && (
          <Indent>
            <MetafieldValue
              metafield={metafield}
              value={{
                value: metafield.defaultValue,
                file: metafield.defaultFile,
              }}
              onChange={action(({ value, file }) => {
                metafield.setInput('defaultValue', value)
                metafield.setInput('defaultFile', file)
              })}
              errors={[
                ...metafield.backendValidationErrors.defaultValue ?? [],
                ...metafield.backendValidationErrors.defaultFile ?? [],
              ]}
            />
          </Indent>
        )}
      </>
    )
  }
}

@observer
export class MetafieldValue extends Component {
  static propTypes = {
    label: PropTypes.node,
    metafield: PropTypes.instanceOf(Metafield).isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string.isRequired),
  }

  static defaultProps = {
    errors: [],
  }

  render() {
    const { label, metafield, value, onChange, errors } = this.props

    const props = {
      label,
      noLabel: !label,
      errors,
      'data-test-metafield': metafield.id,
      value: value.value,
      onChange: (value_) => onChange({ ...value, value: value_ }),
    }

    if (metafield.type === 'text') {
      if (metafield.textLong) {
        return <TargetTextArea {...props} />
      } else {
        return <TargetTextInput {...props} />
      }
    } else if (metafield.type === 'check') {
      return <TargetCheckbox {...props} />
    } else if (metafield.type === 'image') {
      return (
        <TargetImage
          {...props}
          value={value.file}
          onChange={(file) => onChange({ ...value, file })}
          previewDefault={PlaceholderImage}
          previewDefaultFileType="image/png"
        />
      )
    } else if (metafield.type === 'choice') {
      return (
        <TargetSelect
          {...props}
          multiple={metafield.choiceMultiple}
          options={metafield.choiceOptions.map((value) => ({ value, text: value }))}
        />
      )
    } else if (metafield.type === 'measure') {
      return (
        <TargetNumberInput
          {...props}
          allowDecimal={true}
          fromTarget={(value) => typeof value !== 'number' ? '' : value.toString()}
          toTarget={(value) => value === '' ? null : value.includes('.') ? parseFloat(value) : parseInt(value)}
        />
      )
    } else if (metafield.type === 'format') {
      return <TargetTextInput {...props} />
    } else {
      throw new Error(`unknown metafield type: ${metafield.type}`)
    }
  }
}

@observer
export class Metavalues extends Component {
  static propTypes = {
    model: PropTypes.instanceOf(Model).isRequired,
  }

  @computed get level() {
    const { model } = this.props
    return getLevel(model)
  }

  metafields = new MetafieldStore()

  componentDidMount() {
    this.levelReaction = reaction(
      () => this.level,
      action((level) => {
        this.metafields.clear()
        this.metafields.params['.entry_level'] = level
        this.metafields.fetch()
      }),
      { fireImmediately: true },
    )
    this.metafieldsReaction = reaction(
      () => `${this.props.model.cid}:${this.metafields.map(({ id }) => id).join(',')}`,
      action(() => {
        const { model } = this.props
        for (const metafield of this.metafields.models) {
          if (metafield.default && !model.metavalues.models.some((metavalue) => metavalue.metafield.id === metafield.id)) {
            model.metavalues.add({
              metafield: metafield.toJS(),
              value: metafield.defaultValue,
              file: metafield.defaultFile,
            })
          }
        }
      }),
    )
  }

  componentWillUnmount() {
    this.levelReaction()
    this.metafieldsReaction()
  }

  render() {
    const { model } = this.props
    return this.metafields.map((metafield) => {
      const metavalue = model.metavalues.find((metavalue) => metavalue.metafield.id === metafield.id)
      return (
        <MetafieldValue
          key={metafield.cid}
          label={metafield.name}
          metafield={metafield}
          value={
            metavalue === undefined
            ? { value: null, file: null }
            : { value: metavalue.value, file: metavalue.file }
          }
          onChange={action(({ value, file }) => {
            if (metavalue === undefined) {
              model.metavalues.add({
                metafield: metafield.toJS(),
                value,
                file,
              })
            } else {
              metavalue.setInput('value', value)
              metavalue.setInput('file', file)
            }
          })}
        />
      )
    })
  }
}

function getFilters(store) {
  switch (store.constructor.backendResourceName) {
    case 'article_type':
      return { article_type: '' }
    case 'load_carrier':
      return { load_carrier: '' }
    case 'batch':
      return {
        article_type: 'batch_type.article_type.',
        load_carrier: 'load_carrier.',
      }
    default:
      return {}
  }
}

@observer class TargetNumberFilter extends Component {
  static propTypes = TargetNumberInput.propTypes

  quantifiers = [
    { value: ':gte', text: t('form.greaterThanOrEqual') },
    { value: ':lte', text: t('form.lowerThanOrEqual') },
  ]
  @observable quantifier = (
    this.quantifiers.find(({ value }) => (
      this.props.target.params[this.props.name + value] !== undefined
    )) ??
    this.quantifiers[0]
  ).value

  render() {
    const { name, afterChange, ...props } = this.props
    return (
      <TargetNumberInput
        {...props}
        name={name + this.quantifier}
        contentProps={{
          label: (
            <Dropdown
              value={this.quantifier}
              onChange={action((e, { value }) => {
                const { target, name } = this.props
                if (value != this.quantifier && target.params[name + this.quantifier]) {
                  target.params[name + value] = target.params[name + this.quantifier]
                  delete target.params[name + this.quantifier]
                  afterChange()
                }
                this.quantifier = value
              })}
              options={this.quantifiers}
            />
          ),
          labelPosition: 'left',
        }}
      />
    )
  }
}

@observer
export class Metafilters extends Component {
  static propTypes = {
    store: PropTypes.instanceOf(Store).isRequired,
    fetch: PropTypes.func.isRequired,
    debouncedFetch: PropTypes.func.isRequired,
  }

  @computed get filters() {
    const { store } = this.props
    return getFilters(store)
  }

  metafields = new MetafieldStore()

  componentDidMount() {
    this.levelsReaction = reaction(
      () => Object.keys(this.filters).sort().join(','),
      action((levels) => {
        this.metafields.clear()
        this.metafields.params['.entry_level:in'] = levels
        this.metafields.fetch()
      }),
      { fireImmediately: true },
    )
  }

  componentWillUnmount() {
    this.levelsReaction()
  }

  render() {
    const { store, fetch, debouncedFetch } = this.props
    return this.metafields.map((metafield) => {
      const props = {
        key: metafield.id,
        'data-test-metafield-filter': metafield.id,
        label: metafield.name,
        target: store,
        name: `.${this.filters[metafield.entryLevel]}metafield(${metafield.id})`,
        afterChange: fetch,
      }

      if (metafield.type === 'text') {
        return (
          <TargetTextInput
            {...props}
            name={props.name + ':icontains'}
            afterChange={debouncedFetch}
          />
        )
      } else if (metafield.type === 'check') {
        return (
          <TargetRadioButtons
            {...props}
            options={BOOL_OPTIONS}
          />
        )
      } else if (metafield.type === 'image') {
        return (
          <TargetRadioButtons
            {...props}
            name={props.name + ':isnull'}
            options={BOOL_OPTIONS}
          />
        )
      } else if (metafield.type === 'choice') {
        return (
          <TargetSelect
            {...props}
            multiple
            options={metafield.choiceOptions.map((value) => ({ value, text: value }))}
            name={props.name + (metafield.choiceMultiple ? ':overlap' : ':in')}
          />
        )
      } else if (metafield.type === 'measure') {
        return (
          <TargetNumberFilter
            {...props}
            allowDecimal={true}
            afterChange={debouncedFetch}
          />
        )
      } else if (metafield.type === 'format') {
        return (
          <TargetTextInput
            {...props}
            name={props.name + ':icontains'}
            afterChange={debouncedFetch}
          />
        )
      } else {
        throw new Error(`unknown metafield type: ${metafield.type}`)
      }
    })
  }
}
