export default {
  color: {
    red: 'Red',
    orange: 'Orange',
    yellow: 'Yellow',
    olive: 'Olive green',
    green: 'Green',
    teal: 'Turquoise',
    blue: 'Blue',
    violet: 'Violet',
    purple: 'Purple',
    pink: 'Pink',
    brown: 'Brown',
    grey: 'Gray',
    black: 'Black',
  },
  target: {
    TargetColorSelect: {
      label: 'Color',
    }
  }
}
