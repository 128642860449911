import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ProductionLine } from './ProductionLine'
import { ProductionRequest } from './ProductionRequest'
import { Batch } from './Batch'
import { Script } from './Script'
import { PlantationPositionAllocationStore } from './PlantationPositionAllocation'

export class PlantationAllocation extends Model {
  static backendResourceName = 'plantation_allocation'

  @observable id = null
  @observable rack = 1
  @observable layer = 1
  @observable position = 1
  @observable startDate = null
  @observable endDate = null

  relations() {
    return {
      plantationLine: ProductionLine,
      productionRequest: ProductionRequest,
      batch: Batch,
      lightScript: Script,
      nutritionStorageScript: Script,
      nutritionIrrigationScript: Script,
      environmentScript: Script,
      plantationPositionAllocations: PlantationPositionAllocationStore,
    }
  }

  casts() {
    return {
      startDate: Casts.date,
      endDate: Casts.date,
    }
  }
}

export class PlantationAllocationStore extends Store {
  static backendResourceName = 'plantation_allocation'
  Model = PlantationAllocation
}
