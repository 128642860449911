import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { action, observable, set, remove, computed } from 'mobx';
import { Store } from 'mobx-spine';
import { Link } from 'react-router-dom';
import { Dimmer, Loader, Form, Table, Icon, Popup, Button, Menu, Input } from 'semantic-ui-react';
import moment from 'moment';
import { ACTION_DELAY, snakeToCamel, camelToSnake, BOOL_OPTIONS } from '../../helpers';
import bindUrlParams, { encodeUrlByPathname } from '../../helpers/bindUrlParams';
import { Body, ContentContainer, Content, Toolbar, RadioButtons } from 're-cy-cle';
import RightDivider from '../../component/RightDivider';
import styled from 'styled-components';
import { ResponsiveContainer, IconButton } from '../Button';
import HeaderRight from '../HeaderRight';
import axios from 'axios';
import { result, debounce } from 'lodash';
import { Helmet } from 'react-helmet';
import { t } from 'i18n';
import { TargetTextInput, TargetSelect, TargetDatePicker, TargetDateRangePicker, TargetMultiPick, TargetRadioButtons, TargetNumberInput, TargetMultiButtons, TargetTimePicker, TargetTimeRangePicker, TargetWeekPicker, TargetMonthPicker } from '../Target';
import { Toggle } from '../../component/FloatingSidebar';
import { Scrollbars } from 'react-custom-scrollbars';
import MyFilters from '../../component/MyFilters';

export const SidebarToggle = styled(Toggle)`
    > * {
        top: ${({ offset }) => offset};
        z-index: 75;
        ${({
            isActive,
            activeFgColor = '#FFF',
            activeBgColor = 'rgba(0, 0, 0, 0.6)',
            fgColor = '#FFF',
            bgColor = 'transparent',
        }) => `
            color: ${isActive ? activeFgColor : fgColor};
            background-color: ${isActive ? activeBgColor : bgColor};
        `}
    }
`;

export const SidebarContent = styled.div`
    padding: 2rem;
`;

const TARGETS = {
    text: TargetTextInput,
    date: TargetDatePicker,
    select: TargetSelect,
    dateRange: TargetDateRangePicker,
    multiPick: TargetMultiPick,
    search: TargetTextInput,
    radioButtons: TargetRadioButtons,
    multiButtons: TargetMultiButtons,
    bool: TargetRadioButtons,
    number: TargetNumberInput,
    time: TargetTimePicker,
    timeRange: TargetTimeRangePicker,
    week: TargetWeekPicker,
    month: TargetMonthPicker,
};
const DEBOUNCE = {
    text: true,
    search: true,
    number: true,
};
const DEFAULT_PROPS = {
    dateRange: {
        startPlaceholder: t('form.startDate'),
        endPlaceholder: t('form.endDate'),
    },
    timeRange: {
        startPlaceholder: t('form.startTime'),
        endPlaceholder: t('form.endTime'),
    },
    search: {
        label: t('form.search'),
        name: 'search',
    },
    bool: {
        options: BOOL_OPTIONS,
    },
    radioButtons: {
        clearable: true,
    },
};

const SMALL_STYLE_FIRST = {
    paddingRight: 2,
    textAlign: 'center',
};

const SMALL_STYLE = {
    paddingLeft: 2,
    paddingRight: 2,
    textAlign: 'center',
};

const SMALL_STYLE_LAST = {
    paddingLeft: 2,
    paddingRight: 2,
    textAlign: 'center',
};

const ModalContentContainer = styled(ContentContainer)`
    position: relative;
    height: 100%;
    ${SidebarToggle} > * {
        z-index: 1000;
        top: 0px;
        right: ${({ index }) => index * 3 + 1}rem;
        background-color: ${({ isActive }) => isActive ? '#e0e0e0' : '#f8f8f8'} !important;
    }
    ${SidebarToggle} > i.icon {
        margin: 0 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0.9rem !important;
        border-bottom-right-radius: 0.9rem !important;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-top-width: 0;
        color: rgba(0, 0, 0, 0.87) !important;
    }
`;

// Copy pasta from re-cy-cle because the re-cy-cle Sidebar does not work with
// styled-components and these are not exported
const StyledAside = styled.aside`
    ${({ show, medium, small, theme }) => {
        const width = medium ? 450 : small ? 300 : 350;
        return `
            flex: 0 0 auto;
            width: ${width}px;
            background: ${theme.lightColor};
            ${show ? `` : `
                display: none;
                margin-right: -${width}px;
            `}
            transition: margin-right 300ms ease;
        `;
    }};
    display: flex;
    flex-direction: column;
`;

const SidebarFilters = styled.div`
    flex: 1 1 auto;
`;

const SidebarStats = styled.div`
    background-color: #E8E8E8;
    flex: 0 0 auto;
    padding: 1rem;
`;

const SidebarStat = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 1.25em;

    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    &:last-child {
        border-bottom: none;
    }
`;

const SidebarStatLabel = styled.div`
    flex: 0 0 auto;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
`;

const SidebarStatValue = styled.div`
    flex: 1 1 auto;
    text-align: right;
`;

const SidebarStatLoader = styled(Loader)`
    margin: -0.5em 0 !important;
    width: 1em !important;
    height: 1em !important;
    &::before, &::after {
        width: 1em !important;
        height: 1em !important;
        margin: 0 0 0 -0.5em !important;
    }
`;

const SmallFormGroup = styled(Form.Group)`
    > .field {
        min-width: 0 !important;
    }
`;

export class Sidebar extends Component {
    static propTypes = {
        children: PropTypes.node,
        medium: PropTypes.bool,
        small: PropTypes.bool,
        show: PropTypes.bool,
        stats: PropTypes.node,
    };

    static defaultProps = {
        show: true,
    };

    render() {
        const { children, stats, ...props } = this.props;

        let content = (
            <Scrollbars>
                <SidebarContent>{children}</SidebarContent>
            </Scrollbars>
        );

        if (stats) {
            content = (
                <React.Fragment>
                    <SidebarFilters>{content}</SidebarFilters>
                    <SidebarStats>{stats}</SidebarStats>
                </React.Fragment>
            );
        }

        return (
            <StyledAside {...props}>{content}</StyledAside>
        );
    }
}

export const StyledTableHeaderCell = styled(Table.HeaderCell)`
    ${({ onClick }) => onClick ? `
        cursor: pointer !important;
        user-select: none;
    ` : ``}
    white-space: nowrap;
    text-align: ${({ textAlign = 'left' }) => textAlign} !important;
    position: sticky;
    top: 0;
    z-index: 1;
`;

export const SortIconGroup = styled(Icon.Group)`
    margin-right: 0.5rem;
`;

export const SortIcon = styled(Icon)`
    opacity: ${({ active }) => active ? 0.9 : 0.2} !important;
    margin-bottom: 0.1rem !important;
`;

export function getModelName(model) {
    return snakeToCamel(model.backendResourceName.replace(/\//g, '_'));
}

export const ItemButton = ({ icon, label, children = null, ...props }) => {
    const key = props['key'];
    delete props['key'];

    const button = (
        <Button primary size="small" icon={icon} {...props}>
            {children}
        </Button>
    );

    if (label) {
        return (
            <Popup key={key} content={label} trigger={button} />
        );
    }

    return button;
};

// TODO: Rename to TableRow? Need to find projects which actually use this, and
// change those as well.
export const StyledTableRow = styled(Table.Row)`
    ${props => props.deleted && `
        opacity: 0.5;
    `}
`;

export const ToolbarButton = ({ ...props }) => (
    <Button primary compact labelPosition="left" {...props} />
);

const PageInput = styled(Input)`
    width: 80px;
    margin-right: 5px;
`;

export const FullDimmable = styled(Dimmer.Dimmable)`
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex: 1;
    > .ui.dimmer {
        pointer-events: none;
    }
`;

@observer
export class LoadingAnimation extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    render() {
        return (
            <Dimmer data-test-loading inverted active={this.props.store.isLoading}>
                <Loader inverted size="big" />
            </Dimmer>
        );
    }
}

@observer
export class PaginationControls extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        fetch: PropTypes.func,
        onFetch: PropTypes.func,
        afterFetch: PropTypes.func,
    };

    @observable
    newPage = 1;

    handleNewPageOpen() {
        this.newPage = this.store.currentPage;
    }

    handleNewPageChange(e, { value }) {
        this.newPage = parseInt(value);
    }

    handleNewPageApply() {
        const { fetch, afterFetch } = this.props;

        let promise = this.props.store.setPage(this.newPage, { fetch: fetch === undefined })
        if (fetch !== undefined) {
          promise = promise.then(() => fetch())
        }

        this.onFetch(promise).then(afterFetch);
    }

    @action getPreviousPage() {
    const { store, fetch } = this.props;
        if (fetch === undefined) {
            return store.getPreviousPage();
        } else {
            if (!store.hasPreviousPage) {
                throw new Error('[mobx-spine] There is no previous page.');
            }
            store.__state.currentPage -= 1;
            return fetch();
        }
    }

    @action getNextPage() {
        const { store, fetch } = this.props;
        if (fetch === undefined) {
            return store.getNextPage();
        } else {
            if (!store.hasNextPage) {
                throw new Error('[mobx-spine] There is no next page.');
            }
            store.__state.currentPage += 1;
            return fetch();
        }
    }

    onFetch(p) {
        const { onFetch } = this.props;

        onFetch && onFetch(p);

        return p;
    }

    render() {
        const { store, afterFetch } = this.props;

        const currentPage = (
            <Menu.Item as="a">
                {store.currentPage}/{store.totalPages}
            </Menu.Item>
        );

        const currentPageWithPopUp = (
            <Popup
                hoverable
                trigger={currentPage}
            >
                <Popup.Content>
                <PageInput
                    size="mini"
                    value={this.newPage || ''}
                    onChange={this.handleNewPageChange.bind(this)}
                />
                <Button primary size="mini" icon="arrow right" onClick={this.handleNewPageApply.bind(this)} />
                </Popup.Content>
            </Popup>
        );

        return (
            <Menu pagination size="mini">

                <Menu.Item icon
                    as="a"
                    onClick= {store.hasPreviousPage ? ( () => this.onFetch(this.getPreviousPage()).then(afterFetch) ) : undefined}
                >

                    <Icon name="chevron left" />
                </Menu.Item>

                {currentPageWithPopUp}

                <Menu.Item icon
                    as="a"
                    onClick={store.hasNextPage ? ( () => this.onFetch(this.getNextPage()).then(afterFetch) ) : undefined}
                >
                    <Icon name="chevron right" />
                </Menu.Item>

            </Menu>
        );
    }
}

@observer
export class TableHeader extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        fetch: PropTypes.func,
        setting: PropTypes.object.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.onSort = this.onSort.bind(this);
    }

    getOrderBy() {
        const { store } = this.props;
        if (store.params.order_by) {
            return store.params.order_by.split(',');
        } else {
            return [];
        }
    }

    setOrderBy(value) {
        const { store } = this.props;

        if (value.length === 0) {
            remove(store.params, 'order_by');
        } else {
            set(store.params, 'order_by', value.join(','));
        }
    }

    @computed get orderBy() {
        return this.getOrderBy();
    }

    set orderBy(value) {
        return this.setOrderBy(value);
    }

    @computed get sortKey() {
        const { setting } = this.props;
        let { sortKey } = setting;

        if (typeof sortKey === 'function') {
            sortKey = sortKey();
        }
        if (typeof sortKey === 'string') {
            sortKey = [sortKey];
        }

        return sortKey;
    }

    sortState() {
        if (!this.sortKey) {
            return null;
        }

        if (this.getOrderBy().includes(this.sortKey[0])) {
            return 'asc';
        } else if (this.getOrderBy().includes(`-${this.sortKey[0]}`)) {
            return 'desc';
        } else {
            return null;
        }
    }

    onSort() {
        const { store } = this.props;

        switch (this.sortState()) {
            case null: // To 'asc'
                this.setOrderBy([...this.sortKey, ...this.orderBy]);
                break;
            case 'asc': // To 'desc'
                this.setOrderBy([
                    ...this.sortKey.map((key) => `-${key}`),
                    ...this.getOrderBy().filter((key) => !this.sortKey.includes(key)),
                ]);
                break;
            case 'desc': // To null
                const descSortKeys = this.sortKey.map((key) => `-${key}`);
                this.setOrderBy(this.getOrderBy().filter((key) => !descSortKeys.includes(key)));
                break;
            default:
                throw new Error('Invalid sortState');
        }

        if (store.updateUrlParams) {
            store.updateUrlParams();
        }

        this.forceUpdate();
        this.fetch();
    }

    render() {
        const { setting } = this.props;
        const { label, collapsing, props } = setting;

        return (
            <StyledTableHeaderCell
                data-test-sort={this.sortKey ? this.sortKey : undefined}
                onClick={this.sortKey ? this.onSort : undefined}
                collapsing={collapsing}
                {...props}
            >
                {this.sortKey && (
                    <SortIconGroup>
                        {/* First Icon is positioned weirdly for some reason so dummy icon */}
                        <Icon />
                        <SortIcon
                            name="sort ascending"
                            active={this.sortState() === 'asc'}
                        />
                        <SortIcon
                            name="sort descending"
                            active={this.sortState() === 'desc'}
                        />
                    </SortIconGroup>
                )}
                {label}
            </StyledTableHeaderCell>
        );
    }

    baseFetch() {
        const { store } = this.props;

        if (this.cancelRequest) {
            this.cancelRequest();
        }

        return store.fetch({
            cancelToken: new axios.CancelToken(c => {
                this.cancelRequest = c;
            }),
        });
   }

    fetch() {
        const { fetch } = this.props;
        return fetch ? fetch() : this.baseFetch();
    }

    debouncedFetch = debounce(this.fetch, ACTION_DELAY);
}


@observer
export default class AdminOverview extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        viewStore: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.object,
    };

    TableHeader = TableHeader;
    Content = Content;

    /**
     * You can override how the default table row looks like by setting this variable. Example:
     *
     * import { StyledTableRow } from 'spider/semantic-ui/Admin/Overview';
     *
     * const SpecialTableRow = styled(StyledTableRow)`
     *     background-color: red;
     * `;
     *
     * class ProgressOverviewScreen extends AdminOverview {
     *     TableRow = SpecialTableRow;
     * }
     */
    TableRow = StyledTableRow;
    Toolbar = Toolbar;

    DATE_FORMAT = 'DD-MM-YYYY';

    header = '';
    HeaderRight = HeaderRight
    title = '';
    tabTitlePrefix = null;

    myFilterKey = null;
    myFilterBlacklist = []
    myFilterWhitelist = undefined;
    myFilterProps = {};

    /**
     * If an EditModal component is set (this is expected to inherit from
     * spider/semantic-ui/Admin/Edit/Modal) this will be used instead of urls
     * for the 'edit'-type in buttons and the 'add'-type in the toolbar.
     */
    EditModal = null;

    /**
     * If an EditScreen component is set (this is expected to inherit from
     * spider/semantic-ui/Admin/Edit/Screen) this will be used instead of urls
     * for the 'edit'-type in buttons and the 'add'-type in the toolbar.
     */
    EditScreen = null;

    /**
     * Sync url with store params, so when refreshing, the store will recieve
     * the same params and remembers filter settings.
     */
    bindUrlParams = true;

    /**
     * Directly fetch store when mounted. Disable for performance. Can be a
     * function.
     *
     */
    fetchOnMount() {
        return !this.myFilterKey;
    }

    /**
     * Sometimes you want to show an initial message, before the first fetch occures.
     */
    @observable initialFetchOccured = false;

    @observable baseShowSidebar = null;
    defaultShowSidebar = true;

    @computed get showSidebar() {
        if (this.baseShowSidebar === null) {
            if (this.myFilterKey !== null) {
                const hideStorage = localStorage.getItem(`hide-sidebar-${this.myFilterKey}`);
                if (hideStorage !== null) {
                    this.baseShowSidebar = hideStorage === 'false';
                } else {
                    this.baseShowSidebar = this.defaultShowSidebar;
                }
            } else {
                this.baseShowSidebar = this.defaultShowSidebar;
            }
        }
        return this.baseShowSidebar;
    }

    @observable meta = {};

    /**
     * Add buttons per row. Example:
     * buttons: [
     *    (model) => <Button>{model.id}</Button>
     * ];
     */
    itemButtonProps = {};
    buttons = [];
    toolbar = [];

    /**
     * Render (multiple) sidebars. Only 1 sidebar can be active at the same time.
     * Example:
     *
     * sidebars = [
     * {
     *     trigger: props => <IconButton name="search" {...props} />,
     *     content: () => (
     *         <Form>
     *             {this.finalFilters.map(this.renderFilter.bind(this))}
     *         </Form>
     *     )
     * }, {
     *     trigger: props => <IconButton name="search" {...props} />,
     *     content: () => (
     *         <Form>
     *             {this.finalFilters.map(this.renderFilter.bind(this))}
     *         </Form>
     *     )
     * }];
     */
    sidebars = [];
    @observable sidebarActiveIndex = null;
    sidebarsToggleTopOffset = '53px';

    /**
     * Render (multiple) filters. Example:
     *
     * filters = [
     *     { type: 'text', name: '.name:icontains', label: t('driver.field.name.label') },
     * ]
     */
    // filters = [];

    @computed get next() {
        if (this.props.location) {
        return encodeUrlByPathname(this.store, this.props.location.pathname);
    }

        return null;
    }

    @computed get finalButtons() {
        let buttons = this.getButtons();

        if (typeof buttons === 'function') {
            buttons = buttons();
        }

        return buttons ? buttons.slice() : [];
    }

    @computed get finalFilters() {
        let filters = this.getFilters();

        if (typeof filters === 'function') {
            filters = filters();
        }

        if (filters === true) {
            return true;
        }

        return filters ? filters.slice() : [];
    }

    componentDidMount() {
        if (this.bindUrlParams) {
            this.clearUrlBinding = bindUrlParams({
                store: this.store,
                defaultParams: this.getDefaultParams(),
            });
        }

        if (result(this, 'fetchOnMount')) {
            this.fetch();
        }
    }

    setDimmerMargin() {
        console.log('DIMMER MARGIN');
    }

    componentWillUnmount() {
        if (this.clearUrlBinding) {
            this.clearUrlBinding();
        }
    }

    debouncedFetch = debounce(this.fetch.bind(this), ACTION_DELAY);
    fetch() {
        this.initialFetchOccured = true;

        if (this.cancelRequest) {
            this.cancelRequest();
        }

        return (
            this.store.fetch({
                cancelToken: new axios.CancelToken(c => {
                    this.cancelRequest = c;
                }),
            })
            .then((response) => {
                this.cancelToken = undefined;
                set(this.meta, response.meta);
                return response;
            })
            .then(this.afterFetch)
        );
    }

    afterFetch() {}

    getDefaultParams() {
        return this.params;
    }

    /**
     * Backend handles deleted differently than other filters.
     */
    handleDeletedChange = (name, value) => {
        const store = this.store;

        if (value === 'true') {
            store.params[name] = 'true';
        } else {
            delete store.params[name];
        }

        // Mobx only supports already existing keys when you started @observable.
        // In this case we add / delete a key, so mobx can't properly observe
        // changes. In v4 of mobx this is "fixed" by using set / remove from
        // mobx, but we are alas still stuck in 3.1.2...
        this.forceUpdate();
        store.updateUrlParams();
        store.setPage().then(response => {
            set(this.meta, response.meta);
        });
    }

    getSettings() {
        return this.settings;
    }

    getButtons() {
        return this.buttons;
    }

    getFilters() {
        return this.filters;
    }

    getToolbar() {
        return this.toolbar;
    }

    @computed get mappedSettings() {
        return this.filterSettings(this.mapSettings(this.getSettings()));
    }

    attrSetting(setting) {
        const path = setting.attr.split('.');
        const field = path.pop();

        let model = this.store.Model;
        let label, sortKey;
        if (path.length > 0) {
            let prevModel = null;
            sortKey = '';
            let subPath = '';
            for (const field of path) {
                if (sortKey !== '') {
                    sortKey += '.';
                }
                if (subPath !== '') {
                    subPath += '.';
                }
                sortKey += camelToSnake(field);
                subPath += field;
                prevModel = model;
                model = model.prototype.relations()[field];
                if (model === undefined) {
                    throw new Error(`Not an existing relation: ${subPath}`);
                }
            }
            label = t(`${getModelName(prevModel)}.field.${path[path.length - 1]}.label`);
            sortKey += '.' + camelToSnake(field);

            const rel = path.join('.');
            if (!this.store.__activeRelations.some((activeRel) => (
                activeRel === rel || activeRel.startsWith(`${rel}.`)
            ))) {
                throw new Error(`Not an active relation: ${path.join('.')}`);
            }
        } else {
            label = t(`${getModelName(model)}.field.${field}.label`);
            sortKey = camelToSnake(field);
        }

        return {
            ...setting,
            label: setting.label || label,
            sortKey: setting.sortKey || sortKey,
            attr: (field !== 'id' || ((model.idPrefix === undefined || model.idPrefix === '') && (model.idIcon === undefined || model.idIcon === ''))) ? (obj) => {
                for (const field of path) {
                    obj = obj[field];
                }
                return obj[field];
            } : (obj) => {
                for (const field of path) {
                    obj = obj[field];
                }
                return obj.id && obj.getLink();
            },
        };
    }

    handleSmall(setting, i) {
        if (setting.small) {
            return {
                ...setting,
                collapsing: true,
                props: { style: (
                    i === 0
                    ? SMALL_STYLE_FIRST
                    : i === this.getSettings().length - 1
                    ? SMALL_STYLE_LAST
                    : SMALL_STYLE
                ) },
            };
        } else if (setting.centered) {
            return {
                ...setting,
                props: { style: { textAlign: 'center' } },
            };
        } else {
            return setting;
        }
    }

    /**
     * Generate headings from settings. For now it auto creates labels based
     * on attr.
     */
    mapSettings(settings) {
        return settings.map((rawSetting) => {
            let setting = typeof rawSetting === 'function' ? rawSetting() : rawSetting;

            // Auto add label if it's missings.
            if (typeof setting === 'string') {
                if (setting === '') {
                    setting = {};
                } else {
                    setting = { attr: setting };
                }
            }

            if (
                typeof setting === 'object' &&
                typeof setting.attr === 'string'
            ) {
                setting = this.attrSetting(setting);
            }

            if (
                typeof setting === 'object' &&
                typeof setting.label === 'function'
            ) {
                setting.label = setting.label();
            }

            return this.handleSmall(setting);
        });
    }

    filterSettings(settings) {
        const filtered = [];

        for (let { include = true, ...setting } of settings) {
            if (typeof include === 'function') {
                include = include();
            }
            if (include) {
                filtered.push(setting);
            }
        }

        return filtered;
    }

    generateSearchParams() {
        if (this.next) {
            return `?next=${this.next}`;
        }

        return '';
    }

    renderTitle() {
        return this.title && (
            <this.HeaderRight as="h1" content={this.title}>
                {this.renderTitleRight()}
            </this.HeaderRight>
        );
    }

    renderTitleRight() {
        return this.myFilterKey && (
            <MyFilters
                store={this.store}
                view={this.myFilterKey}
                blacklist={this.myFilterBlacklist}
                whitelist={this.myFilterWhitelist}
                defaultParams={this.getDefaultParams()}
                fromUrl={this.bindUrlParams}
                onFetch={this.fetch.bind(this)}
                {...this.myFilterProps}
            />
        );
    }

    renderTabTitle() {
        if (this.title && this.tabTitlePrefix) {
            return (
                <Helmet>
                    <title>{this.tabTitlePrefix}{this.title}</title>
                </Helmet>
            );
        }
    }

    renderContent() {
        return (
            <React.Fragment>
                <FullDimmable>
                    <LoadingAnimation store={this.store} />
                    <this.Content>
                        {this.renderTitle()}
                        {this.renderOverviewTable.call(this)}
                    </this.Content>
                </FullDimmable>
                {/* How does the modal work? Should refactor to renderSidebars? */}
                {this.renderSidebar({ small: this.modal })}
                {this.renderSidebars()}
            </React.Fragment>
        );
    }

    renderBody() {
        // Needed because calling super.render() will cause problems because of
        // how the @observer decorator changes the render method
        const content = this.renderContent();

        if (this.modal) {
            return (
                <ModalContentContainer>
                    {content}
                </ModalContentContainer>
            );
        }

        return (
            <Body>
                {this.renderTabTitle()}
                <ContentContainer>
                    {content}
                </ContentContainer>
                {this.renderToolbar.call(this)}
            </Body>
        );
    }

    render() {
        return this.renderBody();
    }

    renderDeletedFilter() {
        const params = this.store.params ? this.store.params : {};

        return (
            <Form.Field>
                <label>{t('common.filter.deleted')}</label>
                <RadioButtons
                    name="deleted"
                    onChange={this.handleDeletedChange}
                    value={params.deleted === 'true' ? 'true' : 'false'}
                    options={[
                        { value: 'false', label: t('form.no') },
                        { value: 'true', label: t('form.yes') },
                    ]}
                />
            </Form.Field>
        );
    }

    renderOverviewTable() {
        return (
            <Table {...this.tableProps()}>
                <Table.Header>
                    <Table.Row>
                        {this.mappedSettings.map(this.renderHeader.bind(this))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.store.map(this.renderRow.bind(this))}
                </Table.Body>
            </Table>
        );
    }

    renderHeader(setting, i) {
        return (
            <this.TableHeader key={i} setting={setting} store={this.store} fetch={this.fetch.bind(this)} />
        );
    }

    tableProps() {
        return {};
    }

    rowProps(item, i) {
        return {};
    }

    cellProps(item, setting, i) {
        let props = setting.cellProps || {};
        if (typeof props === 'function') {
            props = props(item, i);
        }
        if (setting.small) {
            props.style = {
                ...(
                    i === 0
                    ? SMALL_STYLE_FIRST
                    : i === this.getSettings().length - 1
                    ? SMALL_STYLE_LAST
                    : SMALL_STYLE
                ),
                ...(props.style || {}),
            };
        }
        if (setting.centered) {
            props.style = {
                textAlign: 'center',
                ...(props.style || {}),
            };
        }

        return props;
    }

    renderRow(item, i) {
        const TableRow = this.TableRow;

        return (
            <TableRow key={item.cid} deleted={item.deleted} {...this.rowProps(item, i)}>
                {this.mappedSettings.map((setting, i) => this.renderCell.bind(this)(
                    item, setting, i,
                ))}
                {this.finalButtons.length > 0 && (
                    <Table.Cell collapsing singleLine textAlign="right">
                        {this.finalButtons.map((button, i) => this.renderButton.bind(this)(
                            item, button, i + this.mappedSettings.length,
                        ))}
                    </Table.Cell>
                )}
            </TableRow>
        );
    }

    getCellValue(item, setting) {
        if (setting.attr === undefined) {
            return undefined
        }

        let val = '';

        if (typeof setting.attr === 'function') {
            val = setting.attr(item);
        } else {
            val = item[setting.attr];
        }
        // Format moments
        if (moment.isMoment(val)) {
            val = val.format(setting.momentFormat || this.DATE_FORMAT);
        }
        // Format booleans
        if (typeof val === 'boolean') {
            val = val ? <Icon name="check" /> : '';
        }

        return val;
    }

    renderCell(item, setting, i) {
        const val = this.getCellValue(item, setting);

        if (val !== undefined) {
            return (
                <Table.Cell key={i} {...this.cellProps(item, setting, i)}>
                    {val}
                </Table.Cell>
            );
        }
        return null;
    }

    removeItem(item) {
        if (window.confirm(t('form.deleteConfirmation'))) {
            return item.delete();
        } else {
            return Promise.reject();
        }
    }

    restoreItem(item) {
        if (window.confirm(t('form.restoreConfirmation'))) {
            return item.restore().then(() => item.deleted = false);
        } else {
            return Promise.reject();
        }
    }

    renderButton(item, button, i) {
        if (typeof button === 'function') {
            return button(item, i);
        }

        let type = button.type;

        if (typeof type === 'function') {
            type = type(item, i);
        }

        switch (type) {
            case 'custom':
                return button.callback(item, i);
            case 'view':
                return (
                    <ItemButton
                        key={i}
                        data-test-view-button={item.id}
                        icon="eye" label={button.viewLabel || t('tooltips.view')}
                        as={Link} to={button.to(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'edit':
                const buttonProps = (
                    this.EditModal
                    ? {}
                    : this.EditScreen
                    ? { as: Link, to: `${this.EditScreen.getUrl(item)}${this.generateSearchParams()}` }
                    : { as: Link, to: `${button.to(item)}${this.generateSearchParams()}` }
                );

                let buttonNode = (item.deleted && !button.editDeleted) ? (
                    <ItemButton
                        data-test-view-button={item.id}
                        key={i}
                        icon="eye" label={button.viewLabel || t('tooltips.view')}
                        {...buttonProps}
                        {...this.itemButtonProps}
                    />
                ) : (
                    <ItemButton
                        data-test-edit-button={item.id}
                        key={i}
                        icon="edit" label={button.editLabel || t('tooltips.edit')}
                        disabled={button.canEdit && !button.canEdit(item)}
                        {...buttonProps}
                        {...this.itemButtonProps}
                    />
                );

                if (this.EditModal) {
                    buttonNode = (
                        <this.EditModal
                            key={i}
                            trigger={buttonNode}
                            model={item}
                            afterDelete={(item) => this.store.models.remove(item)}
                            {...button.modalProps || {}}
                        />
                    );
                }

                return buttonNode;
            case 'hardDelete':
                return (
                    <ItemButton
                        key={i}
                        data-test-hard-delete-button
                        icon="delete" label={button.deleteLabel || t('tooltips.delete')}
                        disabled={button.canDelete && !button.canDelete(item)}
                        onClick={() => this.removeItem(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'delete':
                return (item.deleted) ? (
                    <ItemButton
                        key={i}
                        data-test-redo-button={item.id}
                        icon="redo" label={button.restoreLabel || t('tooltips.restore')}
                        disabled={button.canRestore && !button.canRestore(item)}
                        onClick={() => this.restoreItem(item)}
                        {...this.itemButtonProps}
                    />
                ) : (
                    <ItemButton
                        key={i}
                        data-test-delete-button={item.id}
                        icon="delete" label={button.deleteLabel || t('tooltips.delete')}
                        disabled={button.canDelete && !button.canDelete(item)}
                        onClick={() => this.removeItem(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'download':
                return (
                    <ItemButton
                        key={i}
                        icon="download" label={button.label}
                        as="a" href={button.href(item)}
                        {...this.itemButtonProps}
                    />
                );
            default:
                return null;
        }
    }

    toggleSidebar() {
        this.baseShowSidebar = !this.showSidebar;

        if (this.myFilterKey !== null) {
            if (this.showSidebar) {
                localStorage.setItem(`hide-sidebar-${this.myFilterKey}`, 'false');
            } else {
                localStorage.setItem(`hide-sidebar-${this.myFilterKey}`, 'true');
            }
        }
    }

    toggleSidebars(index) {
        if (this.sidebarActiveIndex === index) {
            this.sidebarActiveIndex = null;
        } else {
            this.sidebarActiveIndex = index;
        }

        // Untested, might have some issues with the new this.sidebars functionality.
        if (this.sidebarActiveIndex !== null) {
            localStorage.setItem(`hide-sidebar-${this.myFilterKey}`, 'false');
        } else {
            localStorage.setItem(`hide-sidebar-${this.myFilterKey}`, 'true');
        }
    }

    renderSidebarTrigger(props) {
        return <IconButton name="search" {...props} />;
    }

    renderSidebar(props = {}) {
        if (
            Array.isArray(this.finalFilters)
            ? !this.finalFilters.length
            : !this.finalFilters
        ) {
            return null;
        }

        return (
            <React.Fragment>
                <SidebarToggle
                    data-test-floating-sidebar-toggle={0}
                    index={0}
                    isActive={this.showSidebar}
                    activeFgColor={this.toggleActiveFgColor}
                    fgColor={this.toggleFgColor}
                    activeBgColor={this.toggleActiveBgColor}
                    bgColor={this.toggleBgColor}
                    offset={this.sidebarsToggleTopOffset}
                >
                    {this.renderSidebarTrigger({ onClick: this.toggleSidebar.bind(this) })}
                </SidebarToggle>
                <Sidebar
                    show={this.showSidebar}
                    stats={this.stats && this.renderStats()}
                    {...props}
                >
                    {this.renderSidebarContent()}
                </Sidebar>
            </React.Fragment>
        );
    }

    renderSidebars() {
        if (!this.sidebars) {
            return null;
        }

        return (
            <React.Fragment>
                {this.sidebars.map(({ trigger }, index) => (
                    <SidebarToggle
                        data-test-floating-sidebar-toggle={index}
                        index={index}
                        isActive={this.sidebarActiveIndex === index}
                        activeFgColor={this.toggleActiveFgColor}
                        fgColor={this.toggleFgColor}
                        activeBgColor={this.toggleActiveBgColor}
                        bgColor={this.toggleBgColor}
                        offset={this.sidebarsToggleTopOffset}
                    >
                        {trigger({ onClick: () => this.toggleSidebars(index) })}
                    </SidebarToggle>
                ))}
                <Sidebar data-test-sidebar={this.sidebarActiveIndex} show={this.sidebarActiveIndex !== null}>
                    {this.sidebarActiveIndex !== null ? this.sidebars[this.sidebarActiveIndex].content() : null}
                </Sidebar>
            </React.Fragment>
        );
    }

    renderSidebarContent() {
        return (
            <Form>
                {this.finalFilters.map(this.renderFilter.bind(this))}
            </Form>
        );
    }

    renderFilter(filter, i) {
        if (typeof filter === 'function') {
            filter = filter();
        }

        let { type, targetProps = {}, afterChange, ...props } = filter;

        if (type === 'custom') {
            const { callback, ...args } = props;
            return callback(args, i);
        }

        if (type === 'group') {
            let filters = props.filters || [];
            delete props.filters;

            let label = props.label || null;
            delete props.label;

            let group = (
                <SmallFormGroup key={i} {...props} {...targetProps}>
                    {filters.map(this.renderFilter.bind(this))}
                </SmallFormGroup>
            );

            if (label !== null) {
                group = (
                    <Form.Field>
                        <label>{label}</label>
                        {group}
                    </Form.Field>
                );
            }

            return group;
        }

        const Target = TARGETS[type];

        const filterFetch = (
            DEBOUNCE[type]
            ? this.debouncedFetch
            : this.fetch.bind(this)
        );

        if (afterChange) {
            const oldAfterChange = afterChange;
            afterChange = (...args) => {
                oldAfterChange(...args);
                filterFetch(...args);
            }
        } else {
            afterChange = filterFetch;
        }

        const defaultProps = DEFAULT_PROPS[type] || {};

        return (
            <Target
                key={i}
                target={this.store}
                afterChange={afterChange}
                autoComplete="off"
                {...defaultProps}
                {...props}
                {...targetProps}
            />
        );
    }

    renderStat({ label, source, format = (x) => x }) {
        let value;
        if (this.store.isLoading) {
            value = (
                <SidebarStatLoader active inline />
            );
        } else {
            if (typeof source === 'string') {
                const metaKey = source;
                source = (store) => store.meta[metaKey];
            }

            value = source(this.store);
            if (value === undefined || value === null || isNaN(value)) {
                value = '-';
            } else {
                value = format(value);
            }
        }

        return (
            <SidebarStat>
                <SidebarStatLabel>{label}</SidebarStatLabel>
                <SidebarStatValue>{value}</SidebarStatValue>
            </SidebarStat>
        );
    }

    renderStats() {
        return this.stats.map(this.renderStat.bind(this));
    }

    renderPaginationControls() {
        return (
            <PaginationControls store={this.store} fetch={this.fetch.bind(this)} />
        );
    }

    @computed get finalToolbar() {
        let toolbar = this.getToolbar();

        if (typeof toolbar === 'function') {
            toolbar = toolbar();
        }

        return toolbar ? toolbar.slice() : [];
    }

    renderToolbar() {
        const toolbar = this.finalToolbar;
        const toolbarButtons = this.renderToolbarButtons();

        if (!toolbar) {
            return null;
        }

        return (
            <this.Toolbar>
                {this.renderPaginationControls()}
                <RightDivider />
                {toolbarButtons && (
                    <ResponsiveContainer>
                        {toolbarButtons}
                    </ResponsiveContainer>
                )}
            </this.Toolbar>
        );
    }

    renderToolbarButtons() {
        return this.finalToolbar.map(this.renderToolbarItem.bind(this));
    }

    renderToolbarItem(item, i) {
        if (typeof item === 'function') {
            return item(i);
        }

        switch (item.type) {
            case 'custom':
                return item.callback();
            case 'add':
                if (item.label === undefined) {
                    item.label = t('form.addButton');
                }

                const buttonProps = (
                    this.EditModal
                    ? {}
                    : this.EditScreen
                    ? { as: Link, to: `${this.EditScreen.getUrl()}${this.generateSearchParams()}` }
                    : { as: Link, to: `${item.to}${this.generateSearchParams()}` }
                );

                let buttonNode = (
                    <ToolbarButton data-test-toolbar-add-button
                        key={i}
                        icon="add" content={item.label}
                        {...buttonProps}
                    />
                );

                if (this.EditModal) {
                    buttonNode = (
                        <this.EditModal
                            key={i}
                            trigger={buttonNode}
                            afterSave={() => this.fetch()}
                            {...item.modalProps || {}}
                        />
                    );
                }

                return buttonNode;
            case 'view':
                return (
                    <ToolbarButton
                        key={i}
                        icon="view" content={item.label}
                        as={Link}
                        to={`${item.to}${this.generateSearchParams()}`}
                    />
                );
            case 'download':
                return (
                    <ToolbarButton
                        key={i}
                        icon="download" content={item.label}
                        as={Link}
                        to={`${item.to}${this.generateSearchParams()}`}
                    />
                );
            default:
                return null;
        }
    }
}
