import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactIntegration } from './ExactIntegration'
import { Unit4Integration } from './Unit4Integration'
import { NavisionIntegration } from './NavisionIntegration'
import { SyncErrorStore } from './SyncError'
import { snakeToCamel, camelToSnake } from 'helpers'

export const TYPE_ICONS = {
  exact: 'exact',
  unit4: 'unit4',
  navision: 'navision',
}

export const TYPE_COLORS = {
  exact: '#ff0000',
  unit4: '#BDCF45',
  navision: '#002050',
}

export class Integration extends Model {
  static backendResourceName = 'integration'
  static omitFields = ['type', 'syncErrorCount']
  static TYPES = ['exact', 'unit4', 'navision']

  @observable id = null
  @observable type = 'exact'
  @observable name = ''
  @observable syncErrorCount = 0
  @observable _isLoadingSyncErrors = 0

  @computed get active() {
    return this[`${snakeToCamel(this.type)}Integration`].active
  }

  relations() {
    return {
      exactIntegration: ExactIntegration,
      unit4Integration: Unit4Integration,
      navisionIntegration: NavisionIntegration,
      syncErrors: SyncErrorStore,
    }
  }

  toBackendAll(...args) {
    const relations = this.__activeCurrentRelations
    this.__activeCurrentRelations = this.__activeCurrentRelations.filter((rel) => (
      !rel.endsWith('Integration') ||
      camelToSnake(rel.slice(0, -'Integration'.length)) === this.type
    ))
    try {
      return super.toBackendAll(...args)
    } finally {
      this.__activeCurrentRelations = relations
    }
  }

  clearSyncErrors() {
    this._isLoadingSyncErrors = true;

    const p = this.api.put(this.url, { sync_errors: [] });
    
    p.catch(() => {}).then(() => this._isLoadingSyncErrors = false);

    return p;
  }
}

export class IntegrationStore extends Store {
  static backendResourceName = 'integration'
  Model = Integration
}
