import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { TopicStore } from './Topic'


export class Category extends Model {
  static backendResourceName = 'category'

  @observable id = null
  @observable name = ''
  @observable icon = null
  @observable autosort = false

  relations() {
    return {
      topics: TopicStore,
    }
  }

  hasStakeholder(stakeholderId) {
    return stakeholderId === null || this.topics.models.some((topic) => topic.hasStakeholder(stakeholderId))
  }
}

export class CategoryStore extends Store {
  Model = Category
  static backendResourceName = 'category'
}
