import React, { Component } from 'react'
import { Loader } from 're-cy-cle'
import styled from 'styled-components'

export const StyledPageLoader = styled(Loader)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
`

export class PageLoader extends Component {
  render() {
    return <StyledPageLoader height={50} {...this.props} />
  }
}
