import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { computed, action, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { ProductionLine } from 'store/ProductionLine'
import ProductionLineEdit from 'container/ProductionLine/Edit'
import { afterSave } from 'spider/helpers/bindUrlParams'

@observer
export default class ProductionLineEditScreen extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    viewStore: PropTypes.object.isRequired,
  }

  constructor(...args) {
    super(...args)

    const id = this.props.match.params.id
    this.productionLine = new ProductionLine(
      { id: id ? parseInt(id) : null },
      {
        relations: [
          'versions.workStations.locations',
          'versions.workStations.dataSources',
          'versions.workStations.machine',
          'versions.workSchedules.workSlots',
          'leaveSlots',
          'leaveCalendars.leaveSlots',
          'factory',
        ],
      }
    )

    if (this.productionLine.isNew) {
      this.productionLine.versions.add({
        version: 1,
        draft: true,
      })
    }
  }

  componentDidMount() {
    this.productionLineReaction = reaction(
      () => `${this.props.match.params.id || 'new'},${this.props.match.params.version || 'latest'}`,
      action((params) => {
        const [id] = params.split(',')

        this.productionLine.clear()
        if (id === 'new') {
          this.productionLine.versions.add({
            version: 1,
            draft: true,
          })
        } else {
          this.productionLine.id = parseInt(id)
          // this.productionLine.setFetchParams({ where: (
          //     version === 'latest'
          //     ? 'versions(latest=true)'
          //     : `versions(version=${version})`
          // ) });
          this.productionLine.fetch()
        }
      }),
      { fireImmediately: true }
    )
  }

  componentWillUnmount() {
    this.productionLineReaction()
  }

  @computed get view() {
    const { match } = this.props
    const view = match.params.view || 'map'
    return view
  }

  @computed get baseUrl() {
    const { match } = this.props
    const { id, version } = match.params

    let baseUrl = '/assets/production-line'
    if (id) {
      baseUrl += `/${id}${version ? `/version/${version}` : ''}/edit`
    } else {
      baseUrl += '/add'
    }

    return baseUrl
  }

  render() {
    const { viewStore, history, match } = this.props
    const version = match.params.version
    return (
      <ProductionLineEdit
        productionLine={this.productionLine}
        version={version ? parseInt(version) : null}
        capabilityStore={this.capabilityStore}
        afterSave={afterSave(history, viewStore, this.productionLine)}
        baseUrl={this.baseUrl}
        view={this.view}
        viewStore={viewStore}
      />
    )
  }
}
