import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { OutShipment } from './OutShipment'
import { SalesOrderLine } from './SalesOrderLine'
import { ProductionRequest } from './ProductionRequest'

export const STATUS_DRAFT = 'draft'
export const STATUS_READY_FOR_PICKUP = 'ready for pickup'
export const STATUS_IN_PROGRESS = 'in progress'
export const STATUS_PACKED = 'packed'
export const STATUS_SHIPPED = 'shipped'

export const FREIGHT_ROAD = 'road'
export const FREIGHT_AIR = 'air'
export const FREIGHT_SEA = 'sea'
export const FREIGHT_PARCEL = 'parcel'

export class OutShipmentLine extends Model {
  static backendResourceName = 'out_shipment_line'

  @observable id = null

  relations() {
    return {
      outShipment: OutShipment,
      salesOrderLine: SalesOrderLine,
      productionRequest: ProductionRequest,
    }
  }
}

export class OutShipmentLineStore extends Store {
  static backendResourceName = 'out_shipment_line'

  Model = OutShipmentLine
}
