import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Issue } from './Issue'
import { User } from './User'
import moment from 'moment'

export class Comment extends Model {
  static backendResourceName = 'comment'

  static STATUSES = ['red', 'orange', 'purple', 'green', 'grey']

  @observable id = null
  @observable comment = ''
  @observable createdAt = moment()

  relations() {
    return {
      issue: Issue,
      createdBy: User,
    }
  }

  casts() {
    return {
      createdAt: Casts.datetime,
    }
  }
}

export class CommentStore extends Store {
  Model = Comment
  static backendResourceName = 'comment'
}
