import { observable } from 'mobx';
import { Casts, Model, Store } from 'store/Base';
import { Script } from './Script';
import { ScriptPackage } from './ScriptPackage';
import { User } from './User';
import moment from 'moment';

export class ScriptNotes extends Model {
    static backendResourceName = 'script_notes';

    @observable id = null;
    @observable body = '';
    @observable createdAt = moment();

    @observable _noteId = undefined;
    @observable _temp = false;

    relations() {
        return {
            script: Script,
            scriptPackage: ScriptPackage,
            user: User,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
        };
    }
}

export class ScriptNotesStore extends Store {
    Model = ScriptNotes;
    static backendResourceName = 'script_notes';
}
