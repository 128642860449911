import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { PurchaseOrderStore } from './PurchaseOrder'
import { SalesOrderStore } from './SalesOrder'

export class Project extends Model {
  static backendResourceName = 'project'

  @observable id = null
  @observable code = ''
  @observable description = ''
  @observable notes = ''
  @observable startDate = null
  @observable endDate = null
  @observable createdAt = null
  @observable updatedAt = null

  relations() {
    return {
      purchaseOrders: PurchaseOrderStore,
      salesOrders: SalesOrderStore,
    }
  }
}

export class ProjectStore extends Store {
  static backendResourceName = 'project'
  Model = Project
}
