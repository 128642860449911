import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ArticleType } from './ArticleType'
import { fromIsoCalendar } from 'helpers/date'

export class HarvestTarget extends Model {
  static backendResourceName = 'harvest_target'

  @observable id = null
  @observable startYear = null
  @observable startWeek = null
  @observable endYear = null
  @observable endWeek = null
  @observable target = 0
  @observable margin = 0

  @computed get targetWithMargin() {
    const multiplier = 1 + this.margin / 100
    return Math.round(this.target * multiplier)
  }

  @computed get startDate() {
    if (this.startYear === null || this.startWeek === null) {
      return null
    }
    return fromIsoCalendar(this.startYear, this.startWeek, 1)
  }

  @computed get endDate() {
    if (this.endYear === null || this.endWeek === null) {
      return null
    }
    return fromIsoCalendar(this.endYear, this.endWeek, 7)
  }

  relations() {
    return {
      articleType: ArticleType,
    }
  }

  casts() {
    return {
      startDate: Casts.date,
      endDate: Casts.date,
    }
  }
}

export class HarvestTargetStore extends Store {
  static backendResourceName = 'harvest_target'
  Model = HarvestTarget
}
