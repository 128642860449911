import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Topic } from './Topic'
import { StakeholderStore } from './Stakeholder'
import { User } from './User'
import { CommentStore } from './Comment'
import { showNotification, showSaveNotification } from 'helpers/notification'
import moment from 'moment'


export const STATUS_COLORS = {
  red: '#E53935',
  orange: '#FB8C00',
  purple: '#8E24AA',
  green: '#43A047',
  blue: '#115da9',
  grey: '#424242',
  yellow: '#EDC307',
}

export const STATUS_PRIO = {
  grey: 0,
  green: 1,
  purple: 2,
  orange: 3,
  red: 4,
}

export const getBlockTopicNameFromLocation = (location) => {
  return `Block ${location}`
}

export const getBlockLocationFromTopicName = (topicName) => {
  // Split on space, then reconnect all the pieces except the first one, which is 'Block'
  return topicName.split(' ').slice(1).join(' ')
}

export class Issue extends Model {
  static backendResourceName = 'issue'

  static STATUSES = ['red', 'orange', 'purple', 'green', 'grey']

  @observable id = null
  @observable name = ''
  @observable description = ''
  @observable status = 'grey'
  @observable createdAt = moment()
  @observable resolvedAt = null
  @observable editable = true
  @observable resolved = false
  @observable blocking = false

  relations() {
    return {
      topic: Topic,
      responsible: StakeholderStore,
      createdBy: User,
      comments: CommentStore,
    }
  }

  resolve() {
    // If a blocking issue is resolved, we need to remove the block
    if (this.blocking) {
      this.api.post('/scheduler/', { type: 'block', location: getBlockLocationFromTopicName(this.topic.name), block: false }).then(() => {
        this.resolved = true
        this.save().then(showSaveNotification)
      }).catch(() => {
        showNotification({
          key: 'requestError',
          message: `Couldn't unblock location, scheduler POST request failed. Not resolving issue.`,
          error: true,
          dismissAfter: 15000,
        })
      })

      return
    }

    this.resolved = true
    this.save().then(showSaveNotification)
  }

  casts() {
    return {
      createdAt: Casts.datetime,
    }
  }

  hasStakeholder(stakeholderId) {
    return stakeholderId === null || this.responsible.models.some((stakeholder) => stakeholder.id === stakeholderId)
  }
}

export class IssueStore extends Store {
  Model = Issue
  static backendResourceName = 'issue'
}
