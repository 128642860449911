import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { StorageLocationStore } from './StorageLocation'

export class Warehouse extends Model {
  static backendResourceName = 'warehouse'

  @observable id = null
  @observable code = ''
  @observable name = ''
  @observable useStorageLocations = false

  relations() {
    return {
      storageLocations: StorageLocationStore
    }
  }
}

export class WarehouseStore extends Store {
  Model = Warehouse
  static backendResourceName = 'warehouse'
}
