import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'


@observer
export default class PrintersList extends Component {
  static propTypes = {
    printers: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  @observable selected = null


  render() {
    const { printers, onSelect } = this.props

    return (
      <List selection>
        {printers.map((printer) => (
          <List.Item
            active={printer === this.selected}
            onClick={() => {
              this.selected = printer
              onSelect(printer)
            }}
          >
            <List.Header>{printer}</List.Header>
          </List.Item>
        ))}
      </List>
    );
  }
}
