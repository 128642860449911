import React from 'react'
import PropTypes from 'prop-types'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import AdminOverview from 'component/AdminOverview'
import { Modal, Button } from 'semantic-ui-react'
import { ProductionLineStore } from 'store/ProductionLine'
import ContentScrollModal from 'spider/semantic-ui/ContentScrollModal'
import styled from 'styled-components'

const StyledModalContent = styled(Modal.Content)`
  padding: 0 !important;
  overflow-y: hidden !important;
`

@observer
export default class SelectModal extends AdminOverview {
  static propTypes = {
    trigger: PropTypes.node.isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  store = new ProductionLineStore({ relations: ['versions.workStations.locations'] })

  @observable open = false

  @action onOpen() {
    this.open = true
    this.store.fetch()
  }

  @action onClose() {
    this.open = false
    this.store.clear()
  }
  modal = true
  fetchOnMount = false
  bindUrlParams = false

  settings = [
    'name',
    {
      label: t('productionLine.field.versions.label'),
      attr: (productionLine) => (
        <Button.Group>
          {productionLine.versions.map((version, i) => (
            <Button
              compact
              key={version.id}
              content={version.version}
              primary={
                !version.draft && (productionLine.versions.length === i + 1 || productionLine.versions.at(i + 1).draft)
              }
              style={version.draft ? { opacity: 0.5 } : undefined}
              onClick={() => {
                this.onClose()
                this.props.onSelect(version, productionLine)
              }}
            />
          ))}
        </Button.Group>
      ),
      cellProps: { style: { textAlign: 'right' } },
      collapsing: true,
    },
  ]

  filters = [
    {
      type: 'text',
      label: t('productionLine.field.name.label'),
      name: '.name:icontains',
    },
  ]

  renderBody() {
    const { trigger } = this.props
    return (
      <ContentScrollModal
        closeIcon
        size="fullscreen"
        open={this.open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        trigger={trigger}
      >
        <Modal.Header>{t('productionLine.selectModal.title')}</Modal.Header>
        <StyledModalContent>{super.renderBody()}</StyledModalContent>
      </ContentScrollModal>
    )
  }
}
