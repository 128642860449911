import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import {PLC} from './Plc'
import {ValueStore} from './Value'


export class Modbus extends Model {
  static backendResourceName = 'modbus'
  @observable id = null
  @observable ipAddress = ''
  @observable heartbeatAlarm = null

  async update(register) {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}update/`, {"register": register}))
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }

  // Register corresponds with register id and not acutal register.register
  async push(register, value) {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}push/`, {"register": register, "value": value}))
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }


  relations() {
    return {
        plc: PLC,
        registers: ValueStore,
      }
  }

}

export class ModbusStore extends Store {
    Model = Modbus
    static backendResourceName = 'modbus'
}
